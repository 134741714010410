// Resources
import React from "react";
import Immutable from "immutable";
import { Link } from "react-router-dom";
// Styles
import { EditorWrapperEl } from "./styles/EditorWrapper";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/text-alignment/lib/plugin.css";
import "prismjs/themes/prism.css"; // add prism.css to add highlights
// Components
import Prism from "prismjs";
import createPrismPlugin from "draft-js-prism-plugin";
import Editor from "@draft-js-plugins/editor";
import CodeUtils from "draft-js-code";
import {
	ContentState,
	EditorState,
	EditorBlock,
	RichUtils,
	getDefaultKeyBinding,
	convertFromRaw
} from "draft-js";
import "draft-js/dist/Draft.css";
import createCounterPlugin from "@draft-js-plugins/counter";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createImagePlugin from "@draft-js-plugins/image";
import createVideoPlugin from "@draft-js-plugins/video";
import createLinkPlugin from "@draft-js-plugins/anchor";

import {
	ItalicButton,
	BoldButton,
	UnderlineButton,
	CodeButton,
} from "@draft-js-plugins/buttons";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import createTextAlignmentPlugin from "@draft-js-plugins/text-alignment";
const prismPlugin = createPrismPlugin({
	// It's required to provide your own instance of Prism
	prism: Prism
});
const textAlignmentPlugin = createTextAlignmentPlugin();
const toolbarPlugin = createToolbarPlugin();
const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();
const linkPlugin = createLinkPlugin();
const counterPlugin = createCounterPlugin();
const linkifyPlugin = createLinkifyPlugin({
	target: "_blank",
	rel: "noreferrer"
});
const inlineToolbarPlugin = createInlineToolbarPlugin({
	structure: [BoldButton, ItalicButton, UnderlineButton, CodeButton, linkPlugin.LinkButton]
});
const mentionPlugin = createMentionPlugin({
	mentionTrigger: "@",
	mentionComponent: (mentionProps) => {
		return (
			<Link className="button" to={mentionProps.mention.link}>
				{mentionProps.children}
				<span style={{ paddingLeft: "0.5em" }}>&rsaquo;</span>
			</Link>
		);
	}
});
const plugins = [
	mentionPlugin,
	textAlignmentPlugin,
	linkifyPlugin,
	toolbarPlugin,
	inlineToolbarPlugin,
	linkPlugin,
	imagePlugin,
	videoPlugin,
	counterPlugin,
	prismPlugin
];
const test_json = (obj) => {
	try {
		let a = JSON.parse(obj); // eslint-disable-line
		return true;
	} catch (e) {
		return false;
	}
};
const toSlug = (str) =>
	str
		.toLowerCase()
		.trim()
		.replace(/\s+/g, "_")
		.replace(/[^a-z0-9_]/gi, "");
class HeadingBlock extends React.Component {
	render() {
		const text = this.props.contentState.getBlockForKey(this.props.block.key).getText();
		return (
			<div id={"goto_" + toSlug(text)} style={{ paddingTop: "2em" }}>
				<EditorBlock {...this.props} />
			</div>
		);
	}
}
class IframeEmbed extends React.Component {
	render() {
		const contentState = this.props.contentState;
		const entity = contentState.getEntity(this.props.block.getEntityAt(0));
		let { src } = entity.getData();
		if (src.match(/.(png|jpg|jpeg|svg|gif)/)) {
			return (
				<div>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
					<img
						src={src}
						alt={src}
						style={{
							maxWidth: "100%",
							maxHeight: "40.25vw"
						}}
					/>
				</div>
			);
		} else if (src.indexOf("youtube.com") !== -1 || src.indexOf("youtube-nocookie.com") !== -1) {
			let v = new URLSearchParams(src.substring(src.indexOf("?"))).get("v");
			let iframeUrl = "";
			if (v && src.indexOf("embed") === -1) {
				iframeUrl = "https://www.youtube-nocookie.com/embed/" + v + "?si=0S98-n_1quVyOF88";
			}
			return (
				<div>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
					<iframe
						width="560"
						height="315"
						src={iframeUrl || src}
						title={src}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
						allowFullScreen
						style={{
							maxWidth: "100%",
							maxHeight: "40.25vw"
						}}
					></iframe>
				</div>
			);
		} else if (src.match(/.(pdf)/)) {
			return (
				<div>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
					<iframe
						src={src + "#toolbar=0&view=Fit"}
						style={{ width: "100%", height: "80vh" }}
						title={src}
					></iframe>
				</div>
			);
		} else if (src.indexOf("docs.google.com/") !== -1 || src.indexOf("calendar.google.com/calendar/embed") !== -1) {
			return (
				<div>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
					<iframe src={src} style={{ width: "100%", height: "80vh" }} title={src}></iframe>
				</div>
			);
		} else if (src.match(/.(doc|docx|ppt|pptx|xls|xlsx)/)) {
			return (
				<div>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
					<iframe
						src={"https://view.officeapps.live.com/op/embed.aspx?src=" + src}
						style={{ width: "100%", height: "80vh" }}
						title={src}
					></iframe>
				</div>
			);
		}
		return (
			<div>
				<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
					{src}
				</a>
				<iframe src={src} style={{ width: "100%", height: "80vh" }} title={src}></iframe>
			</div>
		);
	}
}
class IframePreview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showIframe: false
		};
	}
	onMouseEnter = () => {
		this.setState({ showIframe: true });
	};
	onMouseLeave = () => {
		this.setState({ showIframe: false });
	};
	pdfIcon = () => (
		<svg width="20px" height="20px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.5 8H3V7H3.5C3.77614 7 4 7.22386 4 7.5C4 7.77614 3.77614 8 3.5 8Z" fill="currentColor" />
			<path d="M7 10V7H7.5C7.77614 7 8 7.22386 8 7.5V9.5C8 9.77614 7.77614 10 7.5 10H7Z" fill="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 1.5C1 0.671573 1.67157 0 2.5 0H10.7071L14 3.29289V13.5C14 14.3284 13.3284 15 12.5 15H2.5C1.67157 15 1 14.3284 1 13.5V1.5ZM3.5 6H2V11H3V9H3.5C4.32843 9 5 8.32843 5 7.5C5 6.67157 4.32843 6 3.5 6ZM7.5 6H6V11H7.5C8.32843 11 9 10.3284 9 9.5V7.5C9 6.67157 8.32843 6 7.5 6ZM10 11V6H13V7H11V8H12V9H11V11H10Z"
				fill="currentColor"
			/>
		</svg>
	);
	googleIcon = () => (
		<svg
			fill="currentColor"
			height="20px"
			width="20px"
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 210 210"
		>
			<path
				d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40
	c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105
	S0,162.897,0,105z"
			/>
		</svg>
	);
	officeIcon = () => (
		<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" fill="none" />
			<path d="M3,18l4-1.25V7l7-2V19.5L3.5,18.25,14,22l6-1.25V3.5L14,2,3,5.75Z" />
		</svg>
	);
	render() {
		const contentState = this.props.contentState;
		const entity = contentState.getEntity(this.props.block.getEntityAt(0));
		let { src } = entity.getData();
		if (src.match(/.(png|jpg|jpeg|svg|gif)/)) {
			return (
				<div>
					<button className="btn btn-sm" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
						Image Preview
						{this.state.showIframe && (
							<img
								src={src}
								alt={src}
								style={{
									maxWidth: "100%",
									maxHeight: "40.25vw",
									position: "absolute",
									zIndex: 99
								}}
							/>
						)}
					</button>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
				</div>
			);
		} else if (src.indexOf("youtube.com") !== -1 || src.indexOf("youtube-nocookie.com") !== -1) {
			let v = new URLSearchParams(src.substring(src.indexOf("?"))).get("v");
			let iframeUrl = "";
			if (v && src.indexOf("embed") === -1) {
				iframeUrl = "https://www.youtube-nocookie.com/embed/" + v + "?si=0S98-n_1quVyOF88";
			}
			return (
				<div>
					<button className="btn btn-sm" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
						Video Preview
						{this.state.showIframe && (
							<iframe
								width="560"
								height="315"
								src={iframeUrl || src}
								title={src}
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
								allowFullScreen
								style={{
									maxWidth: "100%",
									maxHeight: "40.25vw",
									position: "absolute",
									zIndex: 99
								}}
							></iframe>
						)}
					</button>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
				</div>
			);
		} else if (src.indexOf(".pdf") !== -1) {
			return (
				<div>
					<button className="btn btn-sm" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
						{this.pdfIcon()} Preview
						{this.state.showIframe && (
							<iframe
								src={src}
								style={{ width: "100%", height: "50vh", position: "absolute", zIndex: 99 }}
								title={src}
							></iframe>
						)}
					</button>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
				</div>
			);
		} else if (src.indexOf("docs.google.com") !== -1 || src.indexOf("calendar.google.com/calendar/embed") !== -1) {
			return (
				<div>
					<button className="btn btn-sm" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
						{this.googleIcon()} Preview
						{this.state.showIframe && (
							<iframe
								src={src}
								style={{ width: "100%", height: "50vh", position: "absolute", zIndex: 99 }}
								title={src}
							></iframe>
						)}
					</button>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
				</div>
			);
		} else if (src.match(/.(doc|docx|ppt|pptx|xls|xlsx)/)) {
			return (
				<div>
					<button className="btn btn-sm" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
						{this.officeIcon()} Preview
						{this.state.showIframe && (
							<iframe
								src={"https://view.officeapps.live.com/op/embed.aspx?src=" + src}
								style={{ width: "100%", height: "50vh", position: "absolute", zIndex: 99 }}
								title={src}
							></iframe>
						)}
					</button>
					<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
						{src}
					</a>
				</div>
			);
		}
		return (
			<div>
				<button className="btn btn-sm" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
					Preview
					{this.state.showIframe && (
						<iframe
							src={src}
							style={{ width: "100%", height: "50vh", position: "absolute", zIndex: 99 }}
							title={src}
						></iframe>
					)}
				</button>
				<a style={{ fontSize: "0.8em" }} href={src} target="_blank" rel="noopener noreferrer">
					{src}
				</a>
			</div>
		);
	}
}

const blockRenderMap = Immutable.Map({
	"header-one": {
		element: "h2"
	},
	"header-two": {
		element: "h3"
	},
	"header-three": {
		element: "h4"
	},
	"header-four": {
		element: "h5"
	},
	"header-five": {
		element: "h6"
	}
});
class ReaderWrapper extends React.Component {
	constructor(props) {
		super(props);
		if (props.article.content) {
			this.state = {
				editorState:
					props.article.content.indexOf('{"blocks"') !== 0 || !test_json(props.article.content)
						? EditorState.createWithContent(ContentState.createFromText(props.article.content))
						: EditorState.createWithContent(convertFromRaw(JSON.parse(props.article.content)))
			};
		} else {
			this.state = { editorState: EditorState.createEmpty() };
		}
		this.toggleBlockType = this._toggleBlockType.bind(this);
		this.toggleInlineStyle = this._toggleInlineStyle.bind(this);

		this.onChange = (editorState) => {
			this.setState({ editorState });
		};
	}
	blockRenderer = (contentBlock) => {
		const type = contentBlock.getType();
		if (contentBlock.getEntityAt(0)) {
			const contentState = this.state.editorState.getCurrentContent();
			const entity = contentState.getEntity(contentBlock.getEntityAt(0));
			const entityType = entity.getType();
			if (entityType === "IframeEmbed") {
				return {
					component: IframeEmbed,
					editable: false
				};
			} else if (entityType === "IframePreview") {
				return {
					component: IframePreview,
					editable: false
				};
			}
		}
		if (type.startsWith("header")) {
			return {
				component: HeadingBlock,
				editable: !this.props.readOnly
			};
		}
		return null;
	};
	focus = () => this.editor.focus();
	_toggleBlockType(blockType) {
		this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
	}
	_toggleInlineStyle(inlineStyle) {
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
	}
	handleDefaultButtonClick = () => {
		const newEditorState = RichUtils.toggleBlockType(this.state.editorState, "unstyled");
		if (newEditorState !== this.state.editorState) {
			this.onChange(newEditorState);
		}
	};

	handleKeyCommand = (command) => {
		const { editorState } = this.state;
		let newState;
		if (CodeUtils.hasSelectionInBlock(editorState)) {
			newState = CodeUtils.handleKeyCommand(editorState, command);
		}
		if (!newState) {
			newState = RichUtils.handleKeyCommand(editorState, command);
		}
		if (newState) {
			this.onChange(newState);
			return "handled";
		}
		return "not-handled";
	};

	keyBindingFn = (evt) => {
		const { editorState } = this.state;
		const command = CodeUtils.getKeyBinding(evt);
		if (evt.keyCode === 9 /* TAB */) {
			if (CodeUtils.hasSelectionInBlock(editorState)) {
				this.onChange(CodeUtils.onTab(evt, editorState));
			} else {
				const newEditorState = RichUtils.onTab(evt, this.state.editorState, 4);
				if (newEditorState !== this.state.editorState) {
					this.onChange(newEditorState);
				}
			}
			return;
		}

		return command || getDefaultKeyBinding(evt);
	};

	handleReturn = (evt) => {
		const { editorState } = this.state;
		if (!CodeUtils.hasSelectionInBlock(editorState)) return "not-handled";

		this.onChange(CodeUtils.handleReturn(evt, editorState));
		return "handled";
	};
	handleLinkClick = (event) => {
		let targetElement = event.target;
		// Traverse up the DOM tree until an <a> tag is found or until the body is reached
		while (targetElement && targetElement.tagName !== 'A' && targetElement !== document.body) {
		  targetElement = targetElement.parentNode;
		}
		if (targetElement && targetElement.tagName === 'A') {
		  if (!targetElement.href.includes(window.location.host)) {
			event.preventDefault();
			window.open(targetElement.href, '_blank');
		  }
		}
	};
	componentWillUnmount() {
		document.removeEventListener('click', this.handleLinkClick);
	}
	componentDidMount() {
		document.addEventListener('click', this.handleLinkClick);
	}

	render() {
		const { editorState } = this.state;
		return (
			<EditorWrapperEl>
				<div className="RichEditor-root">
					<div onClick={this.focus}>
						<Editor
							keyBindingFn={this.keyBindingFn}
							handleKeyCommand={this.handleKeyCommand}
							handleReturn={this.handleReturn}
							blockRenderMap={blockRenderMap}
							blockRendererFn={this.blockRenderer}
							editorState={editorState}
							onChange={this.onChange}
							plugins={plugins}
							customStyleMap={{
								HIGHLIGHT_red: {
									backgroundColor: "var(--red)",
									color: "black",
									padding: "0.1em"
								},
								HIGHLIGHT_orange: {
									backgroundColor: "var(--orange)",
									color: "black",
									padding: "0.1em"
								},
								HIGHLIGHT_yellow: {
									backgroundColor: "var(--yellow)",
									color: "black",
									padding: "0.1em"
								},
								HIGHLIGHT_green: {
									backgroundColor: "var(--green)",
									color: "black",
									padding: "0.1em"
								},
								HIGHLIGHT_blue: {
									backgroundColor: "var(--lightblue)",
									color: "black",
									padding: "0.1em"
								},
								HIGHLIGHT_violet: {
									backgroundColor: "var(--lightviolet)",
									color: "black",
									padding: "0.1em"
								}
							}}
							ref={(element) => {
								this.editor = element;
							}}
							readOnly={true}
						/>
					</div>
				</div>
			</EditorWrapperEl>
		);
	}
}

export default ReaderWrapper;
