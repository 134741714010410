import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
	#light body {
		.form-header .version-name, .form-header .version-menu-wrapper {
			color: var(--txt-d);
			background: var(--bg-l1);
			border: 1px solid var(--bg-l3);
			select {
				color: var(--txt-d);
			}
		}
		.form-header .title {
			color: var(--txt-dg);
		}
		.body-wrapper select {
			color: var(--txt-dg);
			border-color: var(--bg-l3);
		}
		.header-wrapper {
			background: #fff;
			border-bottom: 1px solid var(--bg-l3);
			.type {
				color: #fff;
			}
			/* .btn-wrapper img.home {
				background-image: var(--icon-home-d);
			}
			.btn-wrapper img.home.disabled {
				background-image: var(--icon-home-lg);
			}
			.menu-btn-wrapper .btn-wrapper.clickable:hover > img.home {
				background-image: var(--icon-home-b);
			} */
			.save-all-btn.allowed p {
				color: var(--txt-dg);
			}
		}
		.delete-btn {
			background: var(--bg-lg);
			:hover {
				background: var(--red);
			}
		}
		.header-wrapper .right-wrapper .add-btn {
			color: var(--blue);
			font-weight: 700;
		}
		.header-wrapper .right-wrapper .add-btn:hover {
			color: var(--txt-d);
		}
		.header-wrapper .form-header .line {
			background: var(--bg-l1-3);
		}
		.company-row .menu-hover-wrapper .menu-icon {
			color: var(--txt-d) !important;
		}
		
		.menu-hover-wrapper:hover {
			background: var(--bg-l1);
			box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
		}
	}
`;
export const TopNavWrapperEl = styled.div`
	height: 100%;
	padding-left: 10px;
	text-align: left;
	display: inline-flex;
	width: auto;
	overflow-x: auto;
	overflow-y: hidden;
	.logo-button {
		width: 100%;
		height: 100%;
		display: block;
	}
	& .menu-btn-wrapper {
		flex: 0 0 auto;
		width: 60px;
		height: 60px;
		text-align: center;
		position: relative;
	}
	& .menu-btn-wrapper .btn-wrapper {
		width: 40px;
		height: 40px;
		background: transparent;
		border-radius: 25px;
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		transition: background 0.05s, -moz-transform ease-in-out 0.05s;
	}
	& .btn-wrapper img {
		height: 25px;
		width: 25px;
		margin-top: 9px;
	}
	& .menu-btn-wrapper .btn-wrapper.clickable:hover {
		cursor: pointer;
	}
	& .menu-btn-wrapper .btn-wrapper.clickable:hover > img.home {
		background-image: var(--icon-home-lg);
	}
	& .menu-btn-wrapper .btn-wrapper .home-btn-clickzone {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
	}
	& .form-header {
		height: 100%;
		padding: 18px 0 18px 0;
		margin-right: 12px;
		flex: 0 0 auto;
		display: inline-flex;
	}
	& .form-header .line {
		height: 100%;
		width: 2px;
		background: var(--bg-d0);
		border-radius: 1px;
	}
	& .form-header .title {
		color: white;
		font-size: 14px;
		margin: 4px 0 0 8px;
	}
	& .form-header .title.company {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	& .form-header .title.company:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .form-header .title.legacy {
		margin-left: 20px;
	}
	& .form-header .title.legacy:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	& .form-header .title.csd {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	& .form-header .title.csd.green {
		animation: csd-title-copied-ani 1.5s ease-in-out 1 forwards;
		-webkit-animation: csd-title-copied-ani 1.5s ease-in-out 1 forwards;
		-moz-animation: csd-title-copied-ani 1.5s ease-in-out 1 forwards;
		-o-animation: csd-title-copied-ani 1.5s ease-in-out 1 forwards;
	}
	@keyframes csd-title-copied-ani {
		0% {
			color: var(--blue);
		}
		50% {
			color: var(--blue);
		}
		100% {
			color: white;
		}
	}
	& .form-header .title.csd:hover {
	}
	& .form-header .favicon {
		text-transform: uppercase;
		height: 17px;
		width: 17px;
		margin-top: 3px;
		margin-left: 12px;
	}
	& .form-header .type {
		text-transform: uppercase;
		padding: 6px;
		line-height: 1;
		border-radius: 3px;
		font-size: 11px;
		font-weight: 900;
		letter-spacing: 1px;
		margin-top: 3px;
		margin-left: 12px;
	}
	& .form-header .type.tt {
		background: var(--green);
	}
	& .form-header .type.lc {
		background: var(--violet);
	}
	& .form-header .type.pc {
		background: var(--blue);
	}
	& .form-header .type.em {
		background: var(--blue);
	}
	& .form-header .type.nc {
		background: var(--gray);
	}
	& .form-header .type.ce {
		background: var(--red);
	}
	& .version-menu-wrapper {
		height: 30px;
		margin-top: -3px;
		margin-left: 8px;
		padding: 0 8px;
		border: 1px solid transparent;
		border-radius: 3px;
		position: relative;
		pointer-events: none;
	}
	& .version-menu-wrapper select {
		width: 70px;
		color: white;
		font-size: 13px;
		border: none;
		outline: none;
		padding: 0 8px 0 8px;
		height: 100%;
		background: transparent;
		pointer-events: auto;
		transition: border 0.05s, -moz-transform ease-in-out 0.05s;
	}
	& .version-menu-wrapper select:hover {
		cursor: pointer;
	}
	& .version-menu-wrapper:hover {
		cursor: pointer;
		border: 1px solid var(--blue);
	}
	& .form-header .version-name-wrapper {
		position: relative;
	}
	& .form-header .version-name-warning {
		position: absolute;
		top: 26px;
		width: calc(100% - 12px);
		left: 12px;
		color: white;
		text-align: center;
		font-size: 10px;
		background: var(--red);
		font-weight: 900;
		padding: 3px 0 4px 0;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	& .form-header-shrink {
		flex: 0 1 auto;
		max-width: 800px;
	}
	& .form-header .version-name {
		padding: 5px 10px;
		height: 30px;
		border-radius: 3px;
		font-size: 13px;
		margin-top: -3px;
		margin-left: 12px;
		outline: none;
		background: var(--bg-d1);
		border: 1px solid transparent;
		color: white;
	}
	& .form-header .version-name:focus {
		border: 1px solid var(--blue);
	}
	& .form-header .version-name.long {
		border: 1px solid var(--red);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	& .form-header .delete-btn {
		border: 1px solid transparent;
		height: 30px;
		font-size: 16px;
		padding: 0 10px;
		border-radius: 3px;
		text-align: center;
		margin-left: 8px;
		margin-top: -3px;
		font-weight: 700;
		background: var(--bg-d1);
		transition: background 0.15s, border 0.15s, -moz-transform ease-in-out 0.15s;
		:hover {
			background: var(--red);
			cursor: pointer;
			border: 1px solid var(--red);
		}
	}
	& .form-header .add-btn {
		border: 1px solid transparent;
		height: 30px;
		font-size: 16px;
		padding: 0 10px;
		border-radius: 3px;
		text-align: center;
		margin-left: 8px;
		margin-top: -3px;
		font-weight: 700;
		background: var(--bg-d1);
		transition: background 0.15s, border 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .form-header .add-btn.save {
		border: 1px solid var(--blue);
		background: var(--blue);
		font-size: 13px;
	}
	& .form-header .add-btn span {
		color: white;
		pointer-events: none;
		font-size: 20px;
	}
	& .form-header .add-btn.save span {
		color: var(--txt-d);
		font-size: 13px;
	}
	& .form-header .add-btn:hover {
		cursor: pointer;
		border: 1px solid var(--darkgreen2);
		background: var(--darkgreen2);
	}
	& .form-header .add-btn:hover span {
		color: var(--txt-d);
	}
	& .form-header .add-btn.save:hover {
		border: 1px solid var(--darkblue);
		background: var(--darkblue);
	}
	& .tooltip {
		opacity: 1 !important;
		font-weight: 500;
	}
	& .tooltip span {
		background: rgba(0, 0, 0, 0.3);
		padding: 2px 6px 3px 6px;
		font-size: 11px;
		border-radius: 3px;
		margin-left: 8px;
		position: relative;
		top: -2px;
	}
`;
