// Resources
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { withRouter, Redirect, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Components
import Article from "./Article";
import ReaderWrapper from "./components/body/ReaderWrapper";
import DraftWrapper from "./components/body/DraftWrapper";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import withAuth from "./components/auth/withAuth";

// Redux
import { createStore } from "redux";
//import { Provider } from "react-redux";
import rootReducer from "./reducers";

// Styles
import { WikiWrapperEl, GlobalStyle } from "./components/body/styles/WikiWrapper";
import logo from "./images/logo.svg";

// Globals
const store = createStore(rootReducer);

class WikiWrapper extends Component {
	Auth = new AuthHelperMethods();
	constructor(props) {
		super(props);
		this.state = {
			logout: false,
			editMode: localStorage["editMode"] === "true" || false,
			sort: localStorage["wikiSort"] ? localStorage["wikiSort"] : "default",
			filter: localStorage["wikiFilter"] ? localStorage["wikiFilter"] : "All",
			search: new URLSearchParams(window.location.search).get("s") || "",
			articlesVisible: [],
			nonExactMatches: [],
			articles: [],
			redirect: "",
			navigation: {},
			top_navigation: []
		};
	}
	categories = ["All"];

	fetchWikiAll = () => {
		this.fetchAdminAll();
		this.Auth.fetchWikiAll({ user: this.state.user })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.articles) {
					this.setState(
						{
							articlesVisible: this.filterAndSortArticles(
								res.data.articles,
								this.state.search,
								this.state.sort,
								this.state.filter
							),
							articles: res.data.articles,
							navigation: res.data.articles.find((a) => a.title === "Navigation"),
							top_navigation: res.data.articles.filter(
								(a) => a.category === "Navigation" && a.title !== "Navigation"
							)
						},
						function () {
							console.log(this.state.navigation);
						}
					);
				}
			})
			.catch((err) => {
				console.log("[ fetchWikiAll ] POST error:", err);
			});
	};
	fetchAdminAll = () => {
		this.Auth.fetchAdmin({ user: this.state.user._id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.data.settings) {
					let obj = {};
					res.data.settings.forEach((item) => (obj[item.title] = item.value));
					obj.tags = obj.tags.split("\n");
					obj.departments = JSON.parse(obj.departments);
					obj.copy_data = JSON.parse(obj.copy_data);
					this.setState({ settings: obj });
				}
			})
			.catch((err) => console.error("[ fetchAdminAll ] POST error:", err));
	};
	addArticle = (post_data) => {
		this.Auth.addArticle(post_data)
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					this.setState({
						redirect: "/wiki/" + res.article.slug,
						editMode: true
					});
					// this.props.history.push({
					// 	pathname: "/wiki/" + res.article.slug
					// });
					// get new list of articles
					this.fetchWikiAll();
				}
			})
			.catch((err) => {
				console.log("[ addArticle ] POST error:", err);
			});
	};

	toggleMode() {
		this.setState(
			{
				editMode: !this.state.editMode
			},
			function () {
				localStorage.setItem("editMode", this.state.editMode);
			}
		);
	}

	getColor(dept) {
		return (this.state.settings && this.state.settings.departments[dept]) || { color: "100, 100, 100" };
	}

	calculateMatchScore(item, searchTerm) {
		try {
			const check = (term) => {
				try {
					// If matches full word
					if (term.match(new RegExp(`\\b${searchTerm}\\b`, "i"))) {
						return 3;
					}
					// If matches start of word
					if (term.match(new RegExp(`^${searchTerm}`, "i"))) {
						return 2;
					}
					if (term.match(new RegExp(searchTerm, "i"))) {
						return 1;
					}
				} catch (e) {
					return term.indexOf(searchTerm) !== -1 ? 1 : 0;
				}
				return 0;
			};
			if (item.title && check(item.title)) {
				return 1000 * check(item.title);
			}
			if (item.tags && check(item.tags.join(","))) {
				return 100 * check(item.tags.join(","));
			}
			if (item && check(JSON.stringify(Object.values(item)))) {
				return 10 * check(JSON.stringify(Object.values(item)));
			}
		} catch (e) {}
		return 0;
	}

	searchTermMatches(string, term) {
		if (!term.match(/AND|OR|NOT|\?|\*|\(|\)/)) {
			return string.toLowerCase().indexOf(term.toLowerCase()) !== -1;
		}

		function parseSearchTerm(term) {
			const operators = ["AND", "OR", "NOT"];
			const stack = [];
			let currentTerm = "";
			let inQuotes = false;

			for (let i = 0; i < term.length; i++) {
				const char = term[i];

				if (char === '"') {
					inQuotes = !inQuotes;
					currentTerm += char;
				} else if (inQuotes) {
					currentTerm += char;
				} else if (char === " " && operators.includes(currentTerm.toUpperCase())) {
					stack.push({ type: currentTerm.toUpperCase(), value: null });
					currentTerm = "";
				} else if (char === "(" || char === ")") {
					if (currentTerm.length > 0) {
						stack.push({ type: "TERM", value: currentTerm.trim() });
						currentTerm = "";
					}
					stack.push({ type: char, value: null });
				} else if (char === " " && currentTerm.length > 0) {
					stack.push({ type: "TERM", value: currentTerm.trim() });
					currentTerm = "";
				} else if (char === " ") {
					continue;
				} else {
					currentTerm += char;
				}
			}

			if (currentTerm.length > 0) {
				stack.push({ type: "TERM", value: currentTerm.trim() });
			}

			// Check for mismatched quotes
			if (inQuotes) {
				throw new Error("Mismatched quotes in search term");
			}

			// Combine consecutive terms not separated by operators into single terms
			const combinedStack = [];
			let buffer = "";

			for (let i = 0; i < stack.length; i++) {
				const token = stack[i];

				if (token.type === "TERM") {
					if (buffer.length > 0) {
						buffer += " " + token.value;
					} else {
						buffer = token.value;
					}
				} else {
					if (buffer.length > 0) {
						combinedStack.push({ type: "TERM", value: buffer });
						buffer = "";
					}
					combinedStack.push(token);
				}
			}

			if (buffer.length > 0) {
				combinedStack.push({ type: "TERM", value: buffer });
			}

			return combinedStack;
		}

		function evaluateTerm(string, term) {
			function escapeRegexCharacters(str) {
				return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
			}

			const escapedTerm = escapeRegexCharacters(term)
				.replace(/\\\*/g, ".*") // Convert \* to .* for wildcard
				.replace(/\\\?/g, "."); // Convert \? to . for single character wildcard

			const regex = new RegExp(escapedTerm, "i");
			return regex.test(string);
		}

		function evaluateSearchTerms(string, terms) {
			const stack = [];
			let i = 0;

			while (i < terms.length) {
				const term = terms[i];

				if (term.type === "TERM") {
					stack.push(evaluateTerm(string, term.value));
				} else if (term.type === "AND") {
					const left = stack.pop();
					const right = evaluateTerm(string, terms[++i].value);
					stack.push(left && right);
				} else if (term.type === "OR") {
					const left = stack.pop();
					const right = evaluateTerm(string, terms[++i].value);
					stack.push(left || right);
				} else if (term.type === "NOT") {
					const right = evaluateTerm(string, terms[++i].value);
					stack.push(!right);
				} else if (term.type === "(") {
					const subTerms = [];
					let depth = 1;
					i++;
					while (depth > 0 && i < terms.length) {
						if (terms[i].type === "(") depth++;
						else if (terms[i].type === ")") depth--;
						if (depth > 0) subTerms.push(terms[i]);
						i++;
					}
					stack.push(evaluateSearchTerms(string, subTerms));
					i--;
				}

				i++;
			}

			return stack.length > 0 ? stack[0] : false;
		}

		const parsedTerms = parseSearchTerm(term);
		return evaluateSearchTerms(string, parsedTerms);
	}

	filterAndSortArticles(articles, search, sort, filter) {
		let articleList = articles
			.filter((a) => {
				var matches = true;
				if (
					this.state.settings &&
					!this.state.user.profile.email.match(this.state.settings.managers) &&
					a.category.indexOf("Executive") !== -1
				) {
					return false;
				}
				if (a.status === "archived") {
					return filter.indexOf("Archived") !== -1;
				}
				if (sort === "title") {
					matches = this.searchTermMatches(a.title, search);
				} else if (search !== "" && search.length > 1) {
					try {
						if (a.tags && search.indexOf("TAG:") === 0) {
							matches = a.tags
								.map((i) => i.toUpperCase())
								.includes(search.replace("TAG:", "").toUpperCase());
						} else {
							matches = this.searchTermMatches(Object.values(a).join("|"), search);
						}
					} catch (e) {}
				}
				if (filter !== "All") {
					if (filter === "Unpublished") {
						return matches && a.status === "unpublished";
					}
					if (filter === "My Published") {
						return matches && a.status === "published" && a.created_by === this.state.user.profile.email;
					}
					return matches && a.category.indexOf(filter) !== -1;
				}
				return matches && a.status === "published";
			})
			.map((a) => {
				return {
					...a,
					score: this.calculateMatchScore(a, search)
				};
			})
			.sort((a, b) => {
				if (sort === "default") {
					if (!search || search.length < 2) {
						return a.views < b.views ? 1 : -1;
					} else if (search && a.tags && b.tags) {
						return a.score < b.score ? 1 : -1;
					}
				}
				if (sort === "title") return a.score < b.score ? 1 : -1;
				if (sort === "alpha-asc") return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
				if (sort === "alpha-desc") return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
				if (sort === "new") return a.created_on > b.created_on ? -1 : 1;
				if (sort === "recently-updated") return a.updatedAt > b.updatedAt ? -1 : 1;
				if (sort === "most-viewed") return a.views > b.views ? -1 : 1;
				if (sort === "recently-viewed" && localStorage["wikiHistory"]) {
					return localStorage["wikiHistory"].indexOf(a.slug) > localStorage["wikiHistory"].indexOf(b.slug)
						? -1
						: 1;
				}
				return 0;
			});
		return articleList;
	}

	handleFormChange = (e) => {
		let opts = {};
		if (e && e.target) {
			opts[e.target.name] = e.target.value;
			if (e.target.name === "search") {
				document.getElementById("wikiSearch").value = e.target.value;
			}
		}
		this.setState(opts, () => {
			localStorage["wikiSort"] = this.state.sort;
			localStorage["wikiFilter"] = this.state.filter;
			this.updateArticleList();
		});
	};

	updateArticleList = () => {
		const wikiSearch = document.getElementById("wikiSearch");
		const searchTerm = wikiSearch.value;
		let nonExactMatches = [];
		if (searchTerm.split(" ").length > 1) {
			const words = searchTerm.split(/\s+/);
			for (let i = 1; i < words.length; i++) {
				const variation = words.slice(0, i).join(" ") + " AND " + words.slice(i).join(" ");
				const currentMatches = this.filterAndSortArticles(
					this.state.articles,
					variation,
					this.state.sort,
					this.state.filter
				);
				if (
					nonExactMatches.length === 0 ||
					(currentMatches.length > 0 && nonExactMatches.length > currentMatches.length)
				) {
					nonExactMatches = currentMatches;
				}
			}
			nonExactMatches = this.filterAndSortArticles(
				this.state.articles,
				searchTerm.replace(/ +/g, " AND "),
				this.state.sort,
				this.state.filter
			);
		}

		this.setState({
			search: searchTerm,
			articlesVisible: this.filterAndSortArticles(
				this.state.articles,
				searchTerm,
				this.state.sort,
				this.state.filter
			),
			nonExactMatches: nonExactMatches
		});
	};

	generateDocumentOutline = (content) => {
		let outline = [];
		if (!content) return;
		try {
			let d = JSON.parse(content);
			if (d.blocks) {
				d.blocks.forEach((block) => {
					if ((block.type === "header-one" || block.type === "header-two") && block.text) {
						outline.push(block);
					}
				});
			}
		} catch (e) {}
		return outline;
	};

	toSlug = (str) =>
		str
			.toLowerCase()
			.trim()
			.replace(/\s+/g, "_")
			.replace(/[^a-z0-9_]/gi, "");

	angleIcon = (
		<svg width="10px" height="10px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="currentColor" />
		</svg>
	);

	_handleLogout = () => {
		if (window.location.href.indexOf("localhost") !== -1) return;
		this.setState({ logout: true }, function () {
			this.props.history.replace("/login");
		});
	};

	componentWillUnmount() {
		if (this.state.logout) this.Auth.logout();
	}

	componentDidMount() {
		let cookies = new Cookies();
		let theme = cookies.get("theme") || "dark";
		document.documentElement.id = theme === "light" ? "light" : "dark";

		if (this.Auth.getToken()) {
			this.Auth.getProfile()
				.then((res) => {
					if (res.success && res.data && res.data.profile) {
						// Save profile data in redux
						res.data.profile._id = res.data.id;
						store.dispatch({
							type: "PROFILE",
							profile: res.data.profile
						});

						// Save profile data in state
						this.setState(
							{
								user: res.data,
								profile: res.data.profile,
								userId: res.data.id,
								ws: res.data.ws
							},
							this.fetchWikiAll
						);
					} else {
						console.log("[ componentDidMount ] Server error:", res);
						this._handleLogout();
					}
				})
				.catch((err) => {
					console.log("[ componentDidMount ] POST error:", err);
					this._handleLogout();
				});
		} else {
			console.log("[ componentDidMount ] Token not found");
			this._handleLogout();
		}
	}

	renderArticlePreview(article, nonexact) {
		return (
			<article key={article._id} className="article-preview">
				<div className="article-head">
					<h2>
						<Link to={"/wiki/" + article.slug + (this.state.search ? "?search=" + this.state.search : "")}>
							{article.title}
						</Link>
					</h2>
					{this.state.sort === "recently-updated" && (
						<small className="badge">
							Last updated <time>{new Date(article.updatedAt).toLocaleDateString()}</time>
						</small>
					)}
					{this.state.sort === "new" && article.created_on && (
						<small className="badge">
							Created <time>{new Date(article.created_on).toLocaleDateString()}</time>
						</small>
					)}
					{this.state.sort === "recently-viewed" &&
						localStorage["wikiHistory"] &&
						localStorage["wikiHistory"].indexOf(article.slug) !== -1 && (
							<small className="badge">Viewed</small>
						)}
					{article.status === "unpublished" && <small className="badge">Unpublished</small>}
					{this.state.sort === "most-viewed" && <small className="badge">{article.views} Views</small>}
				</div>
				<div className="article-body">
					<DraftWrapper
						plainText={true}
						maxLength={420}
						item={article}
						content={article.content}
						search={
							this.state.search && this.state.search.length > 1
								? nonexact
									? this.state.search.replace(/ +/g, " AND ")
									: this.state.search
								: ""
						}
						className={"dont-break-out"}
					/>
				</div>
				<footer className="article-footer">
					<div className="article-categories">
						<div className="article-categories-list">
							{article.category.split(", ").map((cat) => (
								<button
									key={cat}
									onClick={(e) => {
										this.setState(
											{
												filter: cat
											},
											this.handleFormChange
										);
									}}
									style={{
										background: "rgb(" + this.getColor(cat).color + ")"
									}}
								>
									{cat}
								</button>
							))}
							{article.tags &&
								article.tags.map((t) => (
									<button
										className="tag-btn"
										name="search"
										value={"TAG:" + t}
										key={t}
										onClick={this.handleFormChange}
									>
										{t}
									</button>
								))}
						</div>
					</div>
				</footer>
			</article>
		);
	}

	render() {
		let cookies = new Cookies();
		if (this.state.redirect !== "") {
			const redirectPath = this.state.redirect;
			this.setState({ redirect: "" });
			return <Redirect to={redirectPath} />;
		}
		const isManager = this.state.settings && this.state.user.profile.email.match(this.state.settings.managers);
		const isEditor = this.state.settings && this.state.user.profile.email.match(this.state.settings.editors);
		const isTesting = window.location.href.indexOf("test=navigation") !== -1;
		const allTags = [
			...new Set(this.state.articles.filter((a) => a.status !== "archived").flatMap((a) => a.tags))
		].sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1));
		const uncategorizedTags = this.state.settings
			? allTags.filter((c) => this.state.settings.tags.indexOf(c) === -1)
			: [];
		const tagsVisible = [...new Set(this.state.articlesVisible.flatMap((a) => a.tags))];

		let departments = [];
		if (this.state.settings) {
			departments = this.state.settings.departments;
		}
		if (this.state.user && this.state.user.profile && isEditor && this.categories.indexOf("Unpublished") === -1) {
			this.categories.push("Unpublished");
			this.categories.push("My Published");
			departments["Navigation"] = {
				color: "136,103,178"
			};
			if (isManager) {
				departments["Executive"] = {
					color: "136,103,178"
				};
				this.categories.push("Archived");
			}
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>Upsellit Wiki</title>
				</Helmet>
				<WikiWrapperEl>
					<GlobalStyle />
					<header className={"header-wrapper"}>
						<nav className="top-navigation">
							<div className="menu-item" style={{ minWidth: "10em", marginRight: "auto" }}>
								<button className="btn btn-link btn-logo">
									<a href={origin + "/wiki"}>
										<img src={logo} alt={"logo"} className={"icon"} />
									</a>{" "}
									<a
										href={origin + "/wiki"}
										style={{ fontSize: "1.5em", paddingLeft: "1em", letterSpacing: "2px", height: "auto" }}
									>
										Wiki {this.angleIcon}
									</a>
								</button>
								<div className="sub-menu">
									<ul style={{ paddingLeft: "1em" }}>
										<li>
											<a href={origin + "/"} className="btn btn-link btn-block">
												CSD
											</a>
										</li>
										<li>
											<a
												href="https://www2.upsellit.com/admin/control/edit/index.jsp"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Admin
											</a>
										</li>
										<li>
											<a
												href="https://upsellit.lightning.force.com/lightning"
												target="_blank"
												rel="noreferrer"
												className="btn btn-link btn-block"
											>
												Salesforce
											</a>
										</li>
									</ul>
								</div>
							</div>
							{this.state.top_navigation.length > 0 &&
								this.state.top_navigation.map((article, i) => (
									<span className={"menu-item hide-mobile"} key={i}>
										<a
											href={"/wiki/" + article.slug}
											className={
												"btn btn-link" +
												(window.location.pathname === "/wiki/" + article.slug ? " active" : "")
											}
										>
											{article.title}
										</a>
										{article.child_articles && article.child_articles.length > 0 && (
											<div className="sub-menu">
												<ul>
													{article.child_articles
														.map((slug) => this.state.articles.find((a) => a.slug === slug))
														.map(
															(art) =>
																art &&
																art.slug && (
																	<li
																		key={art.slug}
																		className="btn btn-link btn-block"
																	>
																		<a
																			href={"/wiki/" + art.slug}
																			style={{
																				padding: "0.5em",
																				textTransform: "none"
																			}}
																		>
																			{art.title}
																		</a>
																	</li>
																)
														)}
												</ul>
											</div>
										)}
										{
											(!article.child_articles || article.child_articles.length === 0) &&
												this.generateDocumentOutline(article.content).length > 0 && (
													<div className="sub-menu">
														<ul>
															{this.generateDocumentOutline(article.content).map(
																(item, index) => (
																	<li className={item.type} key={index}>
																		<a
																			style={{ padding: "0.5em" }}
																			href={
																				"/wiki/" +
																				article.slug +
																				"#goto_" +
																				this.toSlug(item.text)
																			}
																		>
																			{item.text}
																		</a>
																	</li>
																)
															)}
														</ul>
													</div>
												)
											//  : article.child_articles.length === 0 ? (
											// 	<div className="sub-menu">
											// 		<ReaderWrapper article={article} />
											// 	</div>
											// ) : (
											// 	""
											// )
										}
									</span>
								))}
							<div className="menu-item hide-mobile" style={{ marginLeft: "auto" }}>
								<a
									href={"/wiki"}
									className="btn text-btn"
									title="search"
									style={{
										fontSize: "1em",
										padding: "1em",
										backgroundImage: "var(--icon-search-lg)",
										border: "none",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										minWidth: "5em"
									}}
								>
									{" "}
								</a>
							</div>
							{this.state.user && (
								<div style={{ marginRight: "1em", minWidth: "5em" }}>
									<div className="menu-item">
										<div className="">
											<button
												className="profile-button btn btn-link"
												style={{ backgroundImage: `url(${this.state.user.profile.picture})` }}
											></button>{" "}
											<span style={{ marginTop: "1.25em", display: "inline-block" }}>
												{this.angleIcon}
											</span>
										</div>
										<div className="sub-menu">
											<ul>
												<li>
													<button
														className="btn btn-link btn-block"
														onClick={(e) => {
															let theme = cookies.get("theme") || "dark";
															if (theme === "light") {
																cookies.set("theme", "dark", { path: "/", maxAge: 315360000 });
																document.documentElement.id = "dark";
															} else {
																cookies.set("theme", "light", { path: "/", maxAge: 315360000 });
																document.documentElement.id = "light";
															}
														}}
													>
														<span
															className="icon"
															style={{
																background: "var(--icon-moon-g)"
															}}
														></span>
														Theme
													</button>
												</li>
												{this.state.user && isEditor && (
													<li>
														<button
															onClick={() => this.addArticle(this.state.user)}
															className="btn btn-link btn-block"
															title="Admin only"
														>
															<span className="icon" style={{}}>
																+
															</span>
															Add Article
														</button>
													</li>
												)}
												{this.state.user && isEditor && (
													<li>
														<button
															onClick={() => this.toggleMode()}
															className="btn btn-link btn-block"
															title="Admin only"
														>
															<span
																className="icon"
																style={{
																	background: "var(--icon-wiki-lg)"
																}}
															></span>
															{this.state.editMode ? "Read" : "Edit"} Mode
														</button>
													</li>
												)}
												{this.state.settings && isEditor && (
													<li>
														<a href="/admin/" className="btn btn-link btn-block">
															<span
																className="icon"
																style={{
																	background: "var(--icon-team-lg)"
																}}
															></span>
															Docs Admin
														</a>
													</li>
												)}
												<li>
													<a
														href="/wiki/wiki_browsing_and_editing"
														className="btn btn-link btn-block"
													>
														<span className="icon" style={{}}>
															?
														</span>
														Help
													</a>
												</li>
												<li>
													{this.state.user && (
														<button
															onClick={() => this._handleLogout()}
															className="btn btn-link btn-block"
														>
															<span
																className="icon"
																style={{
																	background: "var(--icon-logout-g)"
																}}
															></span>
															Logout
														</button>
													)}
												</li>
											</ul>
										</div>
									</div>
								</div>
							)}
						</nav>
					</header>
					<Router>
						<Switch>
							<Route path="/wiki/:slug">
								{this.state.settings && (
									<Article
										departments={departments}
										editMode={this.state.editMode}
										user={this.state.user}
										articles={this.state.articles}
										tags={this.state.settings.tags}
										editors={this.state.settings.editors}
										managers={this.state.settings.managers}
										getColor={this.getColor}
										toSlug={this.toSlug}
										generateDocumentOutline={this.generateDocumentOutline}
									/>
								)}
							</Route>
							<Route path="/wiki/">
								<div className={"body-wrapper"}>
									<header className="wiki-header">
										{/* <h1>
											<span>Upsellit&nbsp;Wiki</span>
										</h1> */}

										{isTesting && <nav className="article-navigation">
											{this.state.navigation && this.state.navigation.content && (
												<ReaderWrapper article={this.state.navigation} />
											)}
										</nav>}

										{isTesting && <nav className="tag-menu">
											<h4>Tags</h4>
											{uncategorizedTags.length > 0 && <h5>Uncategorized</h5>}
											{uncategorizedTags.map((c) => (
												<button
													key={c}
													name="search"
													disabled={tagsVisible.indexOf(c) === -1}
													className={tagsVisible.indexOf(c) !== -1 ? "tag-btn" : "btn-muted"}
													value={"TAG:" + c.toUpperCase()}
													onClick={this.handleFormChange}
												>
													{c.toUpperCase()}
												</button>
											))}
											{this.state.settings &&
												this.state.settings.tags.map((c) =>
													c.match(/[$0-9]\./) == null ? (
														<button
															key={c}
															name="search"
															disabled={tagsVisible.indexOf(c) === -1}
															className={
																tagsVisible.indexOf(c) !== -1 ? "tag-btn" : "btn-muted"
															}
															value={"TAG:" + c}
															onClick={this.handleFormChange}
														>
															{c}
														</button>
													) : (
														<h5 key={c} value={c}>
															{c}
														</h5>
													)
												)}
										</nav>}

										<nav className="search-wrapper">
											<div className="sort-wrapper">
												<label htmlFor="sortList">Sort</label>
												<select
													name="sort"
													value={this.state.sort}
													id="sortList"
													onChange={this.handleFormChange}
												>
													<option value="default">Best Match</option>
													<option value="title">Title Search</option>
													<option value="most-viewed">Most Viewed</option>
													{localStorage["wikiHistory"] && (
														<option value="recently-viewed">Recently Viewed</option>
													)}
													<option value="new">Newest</option>
													<option value="recently-updated">Recently Updated</option>
													<option value="alpha-asc">A-Z</option>
													<option value="alpha-desc">Z-A</option>
												</select>
											</div>
											<div className="filter-wrapper">
												<label htmlFor="filterList">Filter</label>
												<select
													name="filter"
													value={this.state.filter}
													id="filterList"
													onChange={this.handleFormChange}
												>
													{this.categories.map((c) => (
														<option key={c} value={c}>
															{c}
														</option>
													))}
													{Object.keys(departments).map((c) => (
														<option key={c} value={c}>
															{c}
														</option>
													))}
												</select>
											</div>

											<div className="input-wrapper">
												<label htmlFor="wikiSearch">Search</label>
												<input
													list="tags"
													type="search"
													placeholder="Enter search term"
													id="wikiSearch"
													name="search"
													defaultValue={this.state.search}
													onChange={(e) => {
														if (e.target.value === "") {
															this.updateArticleList();
														}
													}}
													onKeyDown={(e) => e.key === "Enter" && this.updateArticleList()}
													autoFocus={true}
												/>
												<button
													onClick={() => this.updateArticleList()}
													className="btn"
													style={{
														fontSize: "1em",
														padding: "1em",
														backgroundImage: "var(--icon-search-lg)",
														border: "none",
														backgroundRepeat: "no-repeat"
													}}
													title="Search"
												></button>
											</div>

											{this.state.articles.length > 0 && (
												<div className="article-count">
													Showing {this.state.articlesVisible.length} of{" "}
													{this.state.articles.length}
												</div>
											)}
										</nav>
									</header>

									<main className="article-list">
										{!this.state.articlesVisible || this.state.articlesVisible.length === 0 ? (
											<div>
												{this.state.articles.length > 0 && (
													<p
														style={{
															textAlign: "center",
															fontStyle: "italic",
															marginBottom: "1em"
														}}
													>
														- No matching articles -
													</p>
												)}
												{this.state.nonExactMatches.length > 0 && (
													<div style={{ opacity: "0.75" }}>
														<h4>Partial Matches</h4>
														<div className="article-count">
															Showing {this.state.nonExactMatches.length} of{" "}
															{this.state.articles.length}
														</div>
														{this.state.nonExactMatches.map((a) =>
															this.renderArticlePreview(a, true)
														)}
													</div>
												)}
											</div>
										) : (
											this.state.articlesVisible.map((a) => this.renderArticlePreview(a))
										)}
										<datalist id="tags">
											{this.state.settings &&
												this.state.settings.tags.map((t) => (
													<option
														key={t}
														value={t}
														className={t.indexOf(".") !== -1 ? "disabled" : ""}
													>
														{t}
													</option>
												))}
										</datalist>
									</main>
								</div>
							</Route>
							<Route path="*">
								<p>Route not found</p>
							</Route>
						</Switch>
					</Router>
				</WikiWrapperEl>
			</React.Fragment>
		);
	}
}

export default withRouter(withAuth(WikiWrapper));
