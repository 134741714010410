// Resources
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // https://github.com/GA-MO/react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css";
import { Prompt } from "react-router-dom";
import * as linkify from "linkifyjs"; // https://github.com/Hypercontext/linkifyjs
import DOMPurify from "dompurify"; // https://github.com/cure53/DOMPurify
import moment from "moment";
import queryString from "query-string";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import { field_data } from "../../helpers/getData";
import TopNavWrapper from "./TopNavWrapper";

// Styles
import { FormWrapperEl, GlobalStyle } from "./styles/FormWrapper";

// Subcomponents
import { Images } from "../function/Images";
import { CompanyInfo } from "../function/CompanyInfo";
import { Section } from "../function/Section";
import { Tasks } from "../function/Tasks";
// import { FormHeader } from "../function/FormHeader";
import { Field } from "../function/Field";

let sectionOrder = [
	"Overview",
	"Strategy",
	"Coupon Analytics",
	"Ad Extension Blocker",
	"Desktop Launch",
	"Mobile Launch",
	"Engagement",
	"On-Site Design",
	"Emails",
	"Tracking & Links",
	"Re-engagement Rules",
	"Assets",
	"Internal"
];

// Open links in new tab (securely)
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
	// set all elements owning target to target=_blank
	if ("target" in node) {
		node.setAttribute("target", "_blank");
		node.setAttribute("rel", "noopener");
	}
});

class FormWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			init: true,
			showDisplay: false,
			fields: {},
			// Tasks
			tasks: [],
			tasksCompleted: [],
			tasksCompletedCount: 0,
			tasksPending: [],
			tasksPendingCount: 0,
			// Sockets
			justEdited: false,
			justAddedGroup: false,
			justDeletedGroup: false,
			// Image upload
			images: [],
			processing: false,
			processingMsg: "",
			command_key: false
		};
	}

	_generateTimeStyles = (date) => {
		if (!date) return "";
		const ONE_DAY = 60 * 60 * 1000 * 24; /* ms */
		const ONE_WEEK = 60 * 60 * 1000 * 24 * 7; /* ms */
		const ONE_MONTH = 60 * 60 * 1000 * 24 * 30; /* ms */
		const now = new Date();
		if (now - date < ONE_DAY) {
			return "green";
		} else if (now - date < ONE_WEEK) {
			return "yellow";
		} else if (now - date < ONE_MONTH) {
			return "orange";
		}
		return "";
	};

	_onDrop = (files) => {
		if (files && files.length > 0) {
			// Validate file formats
			for (let i = 0; i < files.length; i++) {
				if (files[i].type !== "image/jpeg" && files[i].type !== "image/png") {
					return alert("Invalid file format detected");
				}
			}

			// Show processing prompt
			this.setState(
				{
					processing: true,
					processingMsg: ""
				},
				() => {
					// Kick off recursive function to upload images
					this.uploadSingleImage(files, 0, [], (imgs) => {
						this.setState({
							processing: false,
							processingMsg: "",
							images: this.state.images.concat(imgs)
						});
					});
				}
			);
		}
	};

	uploadSingleImage = (files, idx, imgs, cb) => {
		// Exit condition
		if (!files[idx]) return cb(imgs);

		// Perform actions
		this.setState(
			{
				processingMsg:
					files.length > 1 ? "Processing (" + (idx + 1) + "/" + files.length + ")" : "Processing..."
			},
			() => {
				const formData = new FormData();
				formData.append("image", files[idx]);
				formData.append("formv_id", this.props.form.vid);
				formData.append("user", this.props.user.given_name + " " + this.props.user.family_name);

				// Send file to server for upload
				this.Auth.uploadSingleImage(formData)
					.then(async (res) => {
						if (res && res.url) {
							imgs.push({
								url: res.url,
								user: this.props.user.given_name + " " + this.props.user.family_name,
								title: "",
								date: new Date()
							});
							setTimeout(() => {
								// Forward progress
								this.uploadSingleImage(files, idx + 1, imgs, cb);
							}, 100);
						} else if (res.error) {
							console.error("[ uploadSingleImage ] RES ERROR ->", res.error);
						} else {
							console.error("[ uploadSingleImage ] UNKNOWN RESPONSE ->", res);
						}
					})
					.catch((err) => {
						console.error("[ uploadSingleImage ] CATCH ERROR ->", err);
					});
			}
		);
	};

	initSockets = () => {
		// Someone else started editing a field
		this.props.socket.on("fieldBeingEdited", (data) => {
			console.log("[ Body/FormWrapper - initSockets - fieldBeingEdited ] data:", data);
			// Set editing text
			const field = document.getElementById(data.field + "_editing_prompt");
			if (field) {
				const child = field.querySelector(".editing_text");
				if (child) {
					child.textContent = data.user + " is editing this field";
					field.classList.add("show");
				}
			}
		});

		// Someone else reverted a field to its original state
		this.props.socket.on("fieldReverted", (data) => {
			this.clearEditingPrompt(data.field);
		});

		// Someone else has just edited a single field
		this.props.socket.on("handleFieldUpdated", (data) => {
			console.log("[ Body/FormWrapper - initSockets - handleFieldUpdated ] data:", data);
			if (this.state.justEdited) {
				this.setState({ justEdited: false });
			} else if (data && data.field) {
				this.clearEditingPrompt(data.field.field_type_id);
				this.updateFieldJustEdited(data.field.field_id, data, true);
			}
		});

		// Someone else has just added a group
		this.props.socket.on("handleGroupAdded", (data) => {
			console.log("[ Body/FormWrapper - initSockets - handleGroupAdded ] data:", data);
			if (this.state.justAddedGroup) {
				this.setState({ justAddedGroup: false });
			} else if (data.form_id && data.fields && data.fields.length > 0 && data.section) {
				// Update all fields returned
				this.appendFields(data.fields, data.section);
			}
		});

		// Someone else has just deleted a group
		this.props.socket.on("handleGroupDeleted", (data) => {
			if (this.state.justDeletedGroup) {
				this.setState({ justDeletedGroup: false });
			} else if (data.group_id && data.section_name) {
				console.log("[ handleGroupDeleted ] data:", data);
				this.removeGroup(data.group_id, data.section_name);
			}
		});
	};

	clearEditingPrompt = (field) => {
		const fieldEl = document.getElementById(field + "_editing_prompt");
		if (fieldEl) {
			const childEl = fieldEl.querySelector(".editing_text");
			if (childEl) {
				childEl.textContent = "";
				fieldEl.classList.remove("show");
			}
		}
	};

	_handleEditFormClicked = (e) => {
		this.props.handleAddCSD(this.props.form.form._id, this.props.form.form.title);
	};

	_handleCloneFormClicked = () => {
		let self = this;
		confirmAlert({
			title: "Are you sure you want to clone this CSD?",
			message:
				"Only clone if you are creating a very similar CSD for a NEW Site ID (not the one you are cloning from). If this is for the same Site ID, please create a new major version within the existing CSD page.",
			overlayClassName: "confirm-alert-bg-wrapper clone",
			buttons: [
				{
					label: "Yes, clone it!",
					onClick: () =>
						self.props.handleCloneCSD(
							self.props.form.form._id,
							self.props.form.form.type,
							self.props.form.form.title
						),
					className: "green"
				},
				{
					label: "No, nevermind",
					onClick: () => console.log("Good call")
				}
			]
		});
	};

	_handleDeleteFormClicked = () => {
		confirmAlert({
			title: "Are you sure you want to delete this CSD? This action cannot be undone.",
			message:
				"\"If that's true, if you don't know who I am, then maybe your best course would be to tread lightly.\"",
			overlayClassName: "confirm-alert-bg-wrapper delete",
			buttons: [
				{
					label: "Yes, delete it!",
					onClick: () => this.props.handleDeleteFormClick(this.props.form.form),
					className: "red"
				},
				{
					label: "No, nevermind",
					onClick: () => console.log("_handleDeleteFormClicked cancelled")
				}
			]
		});
	};

	sktHandleFieldStartedEditing = (fieldId) => {
		if (this.state.fields && this.state.fields[fieldId]) {
			// Build outgoing data object
			const data = {
				room: this.props.form.vid,
				field: this.state.fields[fieldId].field_type_id,
				user: this.props.user.given_name
			};
			// Emit to server
			if (
				this.props.socket &&
				typeof this.props.socket.emit === "function" &&
				data.room &&
				data.field &&
				data.user
			) {
				this.props.socket.emit("fieldStartedEditing", data);
			}
		}
	};

	sktHandleFieldRevertedEditing = (fieldId) => {
		// Build outgoing data object
		const data = {
			room: this.props.form.vid,
			field: this.state.fields[fieldId].field_type_id,
			user: this.props.user.email.split("@")[0]
		};
		// Emit to server
		if (this.props.socket && typeof this.props.socket.emit === "function" && data.room && data.field && data.user) {
			this.props.socket.emit("fieldRevertedEditing", data);
		}
	};

	_createTask = () => {
		this.Auth.createTask({
			vid: this.props.form.vid,
			url: window.location.href
		})
			.then((res) => {
				if (res.error) {
					console.error("[ _createTask ] err:", res.error);
				} else if (res.task) {
					this.setState(
						{
							tasks: this.state.tasks.concat(res.task),
							tasksPending: this.state.tasksPending.concat(res.task)
						},
						function () {
							let t = document.querySelectorAll(".task-content-wrapper textarea");
							if (t && t.length) t[t.length - 1].focus();
						}
					);
				} else {
					console.error("[ _createTask ] ERROR:", res);
				}
			})
			.catch((err) => {
				console.log("[ _createTask ] POST error:", err);
			});
	};

	_updateTask = (post_data) => {
		post_data.url = window.location.href;
		this.Auth.updateTask(post_data)
			.then((res) => {
				if (res.error) {
					console.error("[ _updateTask ] err:", res.error);
				} else if (res.deleted) {
					let tasks = [];
					for (let i = 0; i < this.state.tasks.length; i++) {
						if (this.state.tasks[i]._id !== res.task._id) tasks.push(this.state.tasks[i]);
					}
					const sortedTasks = this.filterCompletedTasks(tasks);
					this.setState({
						tasks: tasks,
						tasksCompleted: sortedTasks[0],
						tasksPending: sortedTasks[1],
						tasksCompletedCount: sortedTasks[2],
						tasksPendingCount: sortedTasks[3]
					});
				} else if (res.task) {
					let tasks = this.state.tasks;
					for (let i = 0; i < tasks.length; i++) {
						if (tasks[i]._id === res.task._id) {
							tasks[i] = res.task;
							break;
						}
					}
					const sortedTasks = this.filterCompletedTasks(tasks);
					this.setState(
						{
							tasks: tasks,
							tasksCompleted: sortedTasks[0],
							tasksPending: sortedTasks[1],
							tasksCompletedCount: sortedTasks[2],
							tasksPendingCount: sortedTasks[3]
						},
						function () {
							const task_el = document.getElementById(res.task._id);
							if (task_el) {
								task_el.classList.add("updated");
								setTimeout(() => {
									task_el.classList.remove("updated");
								}, 1500);
							}
						}
					);
				} else {
					console.error("[ _updateTask ] ERROR:", res);
				}
			})
			.catch((err) => {
				console.log("[ _updateTask ] POST error:", err);
			});
	};

	_handleRevisionHistoryClicked = (e) => {
		if (e.target) {
			const fieldId = e.target.getAttribute("data-id");
			if (fieldId && typeof this.props.handleFieldRevisionsClick === "function") {
				this.props.handleFieldRevisionsClick(fieldId);
			}
		}
	};

	_optionSelected = (e) => {
		const target = e.target;
		if (target && target.value) {
			const id = target.getAttribute("data-id");
			let selected = Array.from(e.target.selectedOptions).map((o) => o.value);
			const getKey = (length) => {
				let result = "";
				const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
				const charactersLength = characters.length;
				for (var i = 0; i < length; i++) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
				}
				return result;
			};
			if (this.getFieldValue(id).indexOf('{"blocks') === 0) {
				selected = `{"blocks":[${selected
					.map(
						(block) =>
							`{"key":"${getKey(5)}","text":"${block.replace(
								/"/g,
								'\\"'
							)}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
					)
					.join(",")}],"entityMap":{}}`;
			} else {
				selected = selected.join("\n");
			}
			this.setState(
				{
					[id]: selected
				},
				function () {
					this.validateField(id);
					this.autosave(id);
				}
			);
		}
	};

	_optionSelectedStatic = (e) => {
		if (e.target) {
			const id = e.target.getAttribute("data-id");
			if (id) {
				this.setState(
					{
						[id]: e.target.value
					},
					function () {
						this.autosave(id);
					}
				);
			}
		}
	};

	_optionSelectedStaticHour = (e) => {
		if (e.target) {
			const id = e.target.getAttribute("data-id");
			let value = this.getFieldValue(id);
			if (id && value && value.indexOf(":") !== -1) {
				this.setState(
					{
						[id]: e.target.value + ":" + value.split(":")[1]
					},
					function () {
						this.autosave(id);
					}
				);
			}
		}
	};

	_optionSelectedStaticMinute = (e) => {
		if (e.target) {
			const id = e.target.getAttribute("data-id");
			let value = this.getFieldValue(id);
			if (id && value && value.indexOf(":") !== -1) {
				this.setState(
					{
						[id]: value.split(":")[0] + ":" + e.target.value
					},
					function () {
						this.autosave(id);
					}
				);
			}
		}
	};

	_toggleChanged = (checked, e, id) => {
		this.setState(
			{
				[id]: checked ? "1" : ""
			},
			function () {
				this.autosave(id);
			}
		);
	};

	autosave = (id) => {
		this.setState(
			{
				[id + "_hasChanges"]: true
			},
			function () {
				this._handleFieldSave(id);
			}
		);
	};

	_datetimeChanged = (id) => {
		return (value) => {
			try {
				if (value) console.log("[ FormWrapper - _datetimeChanged ] value ->", value);
				this.setState(
					{
						[id]: value ? moment(value).format("yyyy-MM-DDTHH:mm:ss") : "",
						[id + "_hasChanges"]: true
					},
					function () {
						if (this.getFieldValue(id) !== this.getFieldValue(id + "_hasChanges")) {
							this.sktHandleFieldStartedEditing(id);
						} else {
							this.sktHandleFieldRevertedEditing(id);
						}
						//return this.autosave(id);
					}
				);
			} catch (e) {
				console.log(e);
			}
		};
	};

	_dateChanged = (id) => {
		return (value) => {
			return this._setDate(id, value);
		};
	};

	_setDate = (id, value) => {
		try {
			if (value) console.log("[ FormWrapper - _setDate ] value ->", value);
			this.setState(
				{
					[id]: value ? moment(value).format("MM/DD/YYYY") : ""
				},
				function () {
					this.autosave(id);
				}
			);
		} catch (e) {
			console.log(e);
		}
	};

	validateField = (name) => {
		this.setState(
			{
				[name + "_hasChanges"]: this.getFieldValue(name) !== this.getFieldValue(name, "orig")
			},
			function () {
				if (this.getFieldValue(name) !== this.getFieldValue(name + "_hasChanges")) {
					// User just edited this field
					this.sktHandleFieldStartedEditing(name);
				} else {
					// User just reverted this field to its original state
					this.sktHandleFieldRevertedEditing(name);
				}
			}
		);
	};

	checkUnsavedFields = (state) => {
		const fields = Object.keys(state);
		let unsavedFieldIds = [];
		if (fields && fields.length > 0) {
			fields.forEach(function (name) {
				if (name.indexOf("_hasChanges") !== -1 && state[name] === true) {
					unsavedFieldIds.push(name.replace("_hasChanges", ""));
				}
			});
		}
		return unsavedFieldIds;
	};

	_handleForceShowHideClicked = (e) => {
		if (e.target) {
			const id = e.target.getAttribute("data-id");
			const type = e.target.getAttribute("data-ty");
			if (id && type) {
				this.Auth.forceShowHideField({ id: id, type: type })
					.then((res) => {
						if (res.error) {
							console.error("[ _handleForceShowHideClicked ] err:", res.error);
						} else if (res.field) {
							this.updateFieldJustEdited(res.field.field_id, res, false);
						} else {
							console.error("[ _handleForceShowHideClicked ] Fields not found:", res);
						}
					})
					.catch((err) => {
						console.error("[ _handleForceShowHideClicked ] POST error:", err);
					});
			}
		}
	};

	_handleFieldKeyUp = (e) => {
		if (e.target && e.target.name) {
			// Compare with orig
			let hasChanges = e.target.value !== this.getFieldValue(e.target.name, "orig");
			// Set change state if needed
			if (hasChanges !== this.getFieldValue(e.target.name, "hasChanges")) {
				this.setState(
					{
						[e.target.name + "_hasChanges"]: hasChanges
					},
					function () {
						if (hasChanges) {
							this.sktHandleFieldStartedEditing(e.target.name);
						} else {
							this.sktHandleFieldRevertedEditing(e.target.name);
						}
					}
				);
			}
		}
	};
	_handleFieldBlur = (e) => {
		// Set state when field is done editing
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	_handleFieldSave = (id) => {
		if (id && this.getFieldValue(id, "hasChanges")) {
			this.setState(
				{
					[id + "_hasChanges"]: false,
					justEdited: true
				},
				function () {
					// Find the field object
					const post_data = {
						originalField: this.state.fields[id],
						newContent: this.getFieldValue(id).trim(),
						formvId: this.props.form.vid
					};
					// Update Field
					this.Auth.updateField(post_data)
						.then((res) => {
							if (res.error) {
								console.error("[ _handleFieldSave ] err:", res.error);
							} else if (res.field) {
								this.updateFieldJustEdited(id, res, true);
							} else {
								console.error("[ _handleFieldSave ] Fields not found:", res);
							}
						})
						.catch((err) => {
							console.error("[ _handleFieldSave ] POST error:", err);
						});
				}
			);
		}
	};

	_handleSaveAllClick = () => {
		if (this.checkUnsavedFields(this.state).length > 0) {
			this.checkUnsavedFields(this.state).forEach((id) => {
				this._handleFieldSave(id);
			});
		}
	};

	postCreateCampaignData = (formData, action) => {
		const form = document.createElement("form");
		let fields = {};
		let sectionsMapping = {};
		for (var i in formData.fields) {
			formData.fields[i].fields.forEach((field) => {
				if (!sectionsMapping[field.section]) {
					sectionsMapping[field.section] = {};
				}
				try {
					const content = JSON.parse(field.content);
					if (content && content.blocks) {
						fields[field.field_type_id] = content.blocks.map((b) => (b.text ? b.text : "")).join("/n");
					} else {
						fields[field.field_type_id] = field.content;
					}
				} catch (e) {
					fields[field.field_type_id] = field.content;
				}
				sectionsMapping[field.section][field.field_type_id] = fields[field.field_type_id];
			});
		}
		console.log("Section Mappings");
		console.log(sectionsMapping);
		if (window.location.href.indexOf("docsqa") !== -1 || window.location.href.indexOf("localhost:3000") !== -1) {
			action = action.replace("www2", "local2");
		}
		if (formData.form._company.adminId) {
			action += "?companyID=" + formData.form._company.adminId;
		}
		form.action = action;
		form.target = "_blank";
		form.method = "POST";
		const data = {
			title: formData.form.title,
			type: formData.form.type,
			adminId: formData.form._company.adminId,
			salesforceId: formData.form._company.salesforceId,
			csdCompanyId: formData.form._company._id,
			csdId: formData.form._id,
			url: formData.form._company.url,
			companyName: formData.form._company.title,
			fields: JSON.stringify(sectionsMapping)
		};
		console.log(data);
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const input = document.createElement("input");
				input.type = "hidden";
				input.name = key;
				input.value = data[key];
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	};

	updateFieldJustEdited = (id, res, incrementSubversion) => {
		console.log("[ updateFieldJustEdited ] res:", res);

		// Update fields on page
		let fields = this.state.fields;
		fields[id] = res.field;
		this.setState(
			{
				fields: fields,
				[id]: res.field.content,
				[id + "_orig"]: res.field.content,
				[id + "_saved"]: true
			},
			function () {
				// Update field in redux
				for (let prop in this.props.form.fields) {
					if (Object.prototype.hasOwnProperty.call(this.props.form.fields, prop)) {
						if (this.props.form.fields[prop] && this.props.form.fields[prop].fields) {
							for (let i = 0; i < this.props.form.fields[prop].fields.length; i++) {
								if (this.props.form.fields[prop].fields[i]) {
									// Show/hide dependent fields
									if (
										res.dependents &&
										res.dependents[this.props.form.fields[prop].fields[i].field_type_id]
									) {
										const change =
											res.dependents[this.props.form.fields[prop].fields[i].field_type_id];
										if (change) {
											this.props.form.fields[prop].fields[i].is_hidden = change.is_hidden;
											this.setState({
												[this.props.form.fields[prop].fields[i].field_id + "_isHidden"]:
													change.is_hidden
											});
										}
									}
									// Update field
									if (this.props.form.fields[prop].fields[i].field_id === id) {
										let new_fields = {
											...this.props.form.fields
										};
										new_fields[prop].fields[i] = res.field;
										// Increment formv sub version
										if (incrementSubversion) {
											for (let k = 0; k < this.props.form.formvs.length; k++) {
												if (this.props.form.formvs[k]._id === this.props.form.vid) {
													this.props.form.formvs[k].version_sub += 1;
													break;
												}
											}
										}
										this.props.handleFieldChanged(new_fields, this.props.form.formvs, id);
									}
								}
							}
						}
					}
				}
				setTimeout(() => {
					this.setState({ [id + "_saved"]: false });
				}, 1500);
			}
		);
	};

	appendFields = (fields, section) => {
		if (this.props.form.fields[section]) {
			let newFields = this.state.fields;
			let newState = {};
			let newStatePost = {};
			for (let i = 0; i < fields.length; i++) {
				const id = fields[i].field_id;
				newFields[id] = fields[i];
				newState[id] = fields[i].content;
				newState[id + "_orig"] = fields[i].content;
				newState[id + "_saved"] = true;
				newStatePost[id + "_saved"] = false;
				newState[id + "_hasChanges"] = false;
				this.props.form.fields[section].fields.push(fields[i]);
			}
			newState.fields = newFields;
			this.setState(newState, function () {
				this.props.handleFieldChanged(this.props.form.fields, this.props.form.formvs);
				// Revert saved status
				setTimeout(() => {
					this.setState(newStatePost);
				}, 1500);
			});
		}
	};

	_checkUrl = (str) => {
		let isAllowedUrl = false;
		if (str) {
			// Write custom checks for types of links we display
			if (str.indexOf("http://") !== -1 || str.indexOf("https://") !== -1) {
				isAllowedUrl = true;
			}
		}
		return isAllowedUrl;
	};

	_handleCopyClicked = (e) => {
		if (e.target) {
			const url = e.target.getAttribute("data-url");
			if (url) {
				if (navigator.clipboard) {
					navigator.clipboard.writeText(url).then(
						function () {
							console.log("Async: Copying to clipboard was successful!");
							e.target.textContent = "Copied";
							e.target.classList.add("copied");
							setTimeout(function () {
								e.target.textContent = "Copy";
								e.target.classList.remove("copied");
							}, 1500);
						},
						function (err) {
							console.error("Async: Could not copy text: ", err);
						}
					);
				}
			}
		}
	};

	_handleAddGroupButtonClicked = (e) => {
		if (e.target) {
			const sectionName = e.target.getAttribute("data-id");
			if (sectionName && !this.state.justAddedGroup) {
				this.setState({ justAddedGroup: true }, function () {
					this.Auth.createGroup({
						formId: this.props.form.form._id,
						formvId: this.props.form.vid,
						sectionName: sectionName
					})
						.then((res) => {
							if (res.error) {
								console.error("[ _handleAddGroupButtonClicked ] err:", res.error);
							} else if (res.form_id && res.fields && res.fields.length > 0 && res.section) {
								// Update all fields returned
								this.appendFields(res.fields, res.section);
								// Scroll to new field or allow multiple attempts to be added first?
							} else {
								console.error("[ _handleAddGroupButtonClicked ] Fields not found:", res);
							}
						})
						.catch((err) => {
							console.error("[ _handleFieldSave ] POST error:", err);
						});
				});
			}
		}
	};

	_handleDeleteGroupButtonClicked = (e) => {
		if (e.target) {
			const groupId = e.target.getAttribute("data-id");
			const sectionName = e.target.getAttribute("data-section");
			if (groupId && sectionName) {
				confirmAlert({
					title: "Are you sure you want to delete this field group? This action cannot be undone.",
					overlayClassName: "confirm-alert-bg-wrapper delete",
					buttons: [
						{
							label: "Yes, Delete It!",
							onClick: () => this.deleteGroup(groupId, sectionName),
							className: "red"
						},
						{
							label: "No, nevermind",
							onClick: () => console.log("Good call")
						}
					]
				});
			}
		}
	};

	deleteGroup = (groupId, sectionName) => {
		if (groupId && sectionName && !this.state.justDeletedGroup) {
			this.setState({ justDeletedGroup: true }, function () {
				this.Auth.deleteGroup({
					formId: this.props.form.form._id,
					formvId: this.props.form.vid,
					groupId: groupId,
					sectionName: sectionName
				})
					.then((res) => {
						if (res.error) {
							console.error("[ deleteGroup ] err:", res.error);
						} else if (res.group_id && res.section_name) {
							console.log("[ deleteGroup ] res:", res);
							this.removeGroup(res.group_id, res.section_name);
						} else {
							console.error("[ deleteGroup ] Fields not found:", res);
						}
					})
					.catch((err) => {
						console.error("[ deleteGroup ] POST error:", err);
					});
			});
		}
	};

	removeGroup = (groupId, sectionName) => {
		// Update fields on page
		let fields = this.state.fields;
		let field_ids_deleted = [];
		for (let prop in fields) {
			if (Object.prototype.hasOwnProperty.call(fields, prop)) {
				if (fields[prop].group_id && fields[prop].group_id === groupId) {
					field_ids_deleted.push(prop);
					delete fields[prop];
				}
			}
		}
		this.setState(
			{
				fields: fields
			},
			function () {
				for (let prop in this.props.form.fields) {
					if (Object.prototype.hasOwnProperty.call(this.props.form.fields, prop)) {
						if (
							this.props.form.fields[prop] &&
							this.props.form.fields[prop].title === sectionName &&
							this.props.form.fields[prop].fields &&
							this.props.form.fields[prop].fields.length > 0
						) {
							for (let i = 0; i < this.props.form.fields[prop].fields.length; i++) {
								if (
									this.props.form.fields[prop].fields[i] &&
									field_ids_deleted.indexOf(this.props.form.fields[prop].fields[i].field_id) !== -1
								) {
									delete this.props.form.fields[prop].fields[i];
								}
							}
						}
					}
				}
				this.props.handleFieldChanged(this.props.form.fields, this.props.form.formvs);
			}
		);
	};

	keyboardShortcutListener(event) {
		// "command + s" to save all fields. Key code 83 is "s"
		if (event.metaKey || event.ctrlKey) {
			if (event.key === "s" || event.keyCode === 83) {
				// save all fields "CTRL/CMD + S"
				event.preventDefault();
				event.target.blur();
				this._handleSaveAllClick();
			} else if (event.key === "e" || event.keyCode === 69) {
				// toggle edit mode "CTRL/CMD + E"
				if (window.location.search.indexOf("&reader=1") !== -1) {
					window.location.search = window.location.search.replace("&reader=1", "");
				} else {
					window.location.search = window.location.search + "&reader=1";
				}
			}
			this.setState({ command_key: true });
		}
	}

	initVersion = () => {
		if (this.props.form && this.props.form.formvs && this.props.form.vid) {
			let main_version = 0;
			for (let i = 0; i < this.props.form.formvs.length; i++) {
				if (this.props.form.formvs[i]._id === this.props.form.vid) {
					main_version = this.props.form.formvs[i].version_main;
					let u = new URLSearchParams(window.location.search);
					u.delete("v");
					let url = window.location.pathname + "?" + u.toString() + "&v=" + main_version;
					window.history.pushState({}, "", url);
					this.setState({
						main_version: main_version
					});
					break;
				}
			}
		} else {
			setTimeout(() => {
				this.initVersion();
			}, 100);
		}
	};
	componentDidMount() {
		let u = new URLSearchParams(window.location.search);
		if (u.get("v") !== this.state.major_version) {
			this.initVersion();
		}
		this.initFields(1);
		window.onbeforeunload = undefined;
		document.body.removeEventListener("keydown", this.keyboardShortcutListener.bind(this));
		document.body.addEventListener("keydown", this.keyboardShortcutListener.bind(this));
		document.body.addEventListener("keyup", (event) => {
			if (!(event.metaKey || event.ctrlKey)) {
				this.setState({ command_key: false });
			}
		});
	}

	componentDidUpdate = () => {
		if (this.checkUnsavedFields(this.state).length > 0) {
			window.onbeforeunload = () => {
				setTimeout(() => {
					// We need to reload sockets here or else the app will crash
					this.props.forceReloadSockets();
				}, 1);
				return true;
			};
		} else {
			window.onbeforeunload = undefined;
		}
	};

	waitForSocketConnection(count) {
		console.log("[ waitForSocketConnection ]", count);
		if (this.props.socket && Object.keys(this.props.socket).length > 0) {
			this.initSockets();
		} else {
			setTimeout(() => {
				this.waitForSocketConnection(count + 1);
			}, 200);
		}
	}

	initFields(count) {
		console.log("[ initFields ]", count);
		if (this.state.init && window.location.href.indexOf("/form") !== -1) {
			if (this.props.form && this.props.form.fields) {
				console.log("------------------------------------------");
				console.log("---------------- FORM BODY ---------------");
				console.log("------------------------------------------");
				console.log("[ initFields ] form:", this.props.form);
				this.waitForSocketConnection(1);
				// Set states
				let fields = {},
					ids = {},
					dupes = {};
				for (let i = 0; i < sectionOrder.length; i++) {
					const section = this.props.form.fields[sectionOrder[i]];
					if (section && section.fields && section.fields.length > 0) {
						// console.log('[ initFields ] section:', section);
						for (let j = 0; j < section.fields.length; j++) {
							// * Design notes id bug fix
							// - Forms that were created prior to 9/24/2021 contained a duplicate field_type_id for "design_notes"
							// - The below line of code is a quick & dirty fix to this (do not remove)
							if (
								section.fields[j].field_type_id === "design_notes" &&
								sectionOrder[i] === "On-Site Design"
							)
								section.fields[j].field_type_id = "onsite_design_notes";

							// Check for dupes
							if (typeof ids[section.fields[j].field_id] === "undefined") {
								// Push to id dict
								ids[section.fields[j].field_id] = [
									{
										id: section.fields[j]._id,
										field_type_id: section.fields[j].field_type_id,
										createdAt: section.fields[j].createdAt
									}
								];
								this.setState({
									[section.fields[j].field_id]: section.fields[j].content || "",
									[section.fields[j].field_id + "_orig"]: section.fields[j].content || "",
									[section.fields[j].field_id + "_hasChanges"]: false,
									[section.fields[j].field_id + "_saved"]: false,
									[section.fields[j].field_id + "_isHidden"]: section.fields[j].is_hidden
								});
								fields[section.fields[j].field_id] = section.fields[j];
							} else {
								// Duplicate field found
								console.error(
									"[ initFields ] DUPLICATE ID FOUND:" +
										section.fields[j].field_id +
										" -> " +
										section.fields[j].field_type_id
								);
								ids[section.fields[j].field_id].push({
									id: section.fields[j]._id,
									field_type_id: section.fields[j].field_type_id,
									createdAt: section.fields[j].createdAt
								});
								console.error(ids[section.fields[j].field_type_id]);
								dupes[section.fields[j].field_id] = ids[section.fields[j].field_id];
							}
						}
					}
				}
				const sortedTasks = this.filterCompletedTasks(this.props.form.tasks);

				// Extract formv images
				let images = [];
				for (let i = 0; i < this.props.form.formvs.length; i++) {
					if (this.props.form.formvs[i]._id === this.props.form.vid) {
						images = this.props.form.formvs[i].images;
						break;
					}
				}

				this.setState(
					{
						init: false,
						fields: fields,
						tasks: this.props.form.tasks,
						tasksCompleted: sortedTasks[0],
						tasksPending: sortedTasks[1],
						tasksCompletedCount: sortedTasks[2],
						tasksPendingCount: sortedTasks[3],
						images: images
					},
					function () {
						// Fix dupes if any exist
						if (Object.keys(dupes).length !== 0) this.fixDupes(dupes);
						setTimeout(() => {
							this.setState({ showDisplay: true });
							// [NEW - 3/8/2022] Display image if image file key is found in url
							this.initProactiveImageDisplay();
						}, 500);
					}
				);
			} else if (count < 50) {
				setTimeout(() => {
					this.initFields(count + 1);
				}, 200);
			}
		}
	}

	initProactiveImageDisplay = () => {
		const params = queryString.parse(window.location.href);
		const fileKey = params && params.img ? params.img : "";
		if (fileKey && fileKey.indexOf("|b|") !== -1) {
			// Image is from bug report
			this.props.handleImageZoom("/s3/images/" + fileKey.split("|b|")[0], fileKey.split("|b|")[1], "bug");
		} else if (fileKey && this.state.images.length > 0) {
			for (let i = 0; i < this.state.images.length; i++) {
				// Image is from CSD
				if (this.state.images[i].url.indexOf(fileKey) !== -1) {
					this.props.handleImageZoom(this.state.images[i], this.props.form.vid, "formv");
					break;
				}
			}
		}
	};

	filterCompletedTasks = (arr) => {
		let completed = [],
			pending = [],
			completedCount = 0,
			pendingCount = 0;
		arr.forEach(function (el) {
			// Display "Force Show" tasks in addition to all "Pending" tasks,  && !el.force_show
			el.status === "Completed" ? completed.push(el) : pending.push(el);
			if (el.status === "Completed") {
				completedCount++;
			} else {
				pendingCount++;
			}
		});
		return [completed, pending, completedCount, pendingCount];
	};

	reportDraftChange = (item, value) => {
		if (value === this.getFieldValue(item)) return;
		let id = item.field_id;
		this.setState(
			{
				[id]: value,
				[id + "_hasChanges"]: true
			},
			function () {
				if (this.getFieldValue(id) !== this.getFieldValue(id + "_hasChanges")) {
					this.sktHandleFieldStartedEditing(id);
				} else {
					this.sktHandleFieldRevertedEditing(id);
				}
			}
		);
	};

	fixDupes(dupes) {
		console.warn("[ initFields ] dupes", dupes);
		let fix_arr = [];
		// Build array of field _ids to fix
		for (let prop in dupes) {
			if (Object.prototype.hasOwnProperty.call(dupes, prop)) {
				// Sort by date and remove latest
				dupes[prop]
					.sort(function (a, b) {
						return new Date(a.createdAt) - new Date(b.createdAt);
					})
					.pop();
				// Add outdated ids to fix arr
				dupes[prop].forEach(function (el) {
					fix_arr.push(el.id);
				});
			}
		}
		console.warn("[ initFields ] fix_arr:", fix_arr);
		if (fix_arr.length > 0) {
			this.Auth.fixFields({ field_ids: fix_arr })
				.then((res) => {
					if (res.error) {
						console.error("[ initFields ] err:", res.error);
					} else if (res.success) {
						console.log("[ initFields ] Successfully fixed duplicate fields");
					} else {
						console.error("[ initFields ] Unknown error:", res);
					}
				})
				.catch((err) => {
					console.log("[ initFields ] POST error:", err);
				});
		}
	}

	_handleTaskDelete = (e) => {
		if (e && e.target && e.target.getAttribute("data-id")) {
			confirmAlert({
				title: "Are you sure you want to delete this task? This action cannot be undone.",
				overlayClassName: "confirm-alert-bg-wrapper delete",
				buttons: [
					{
						label: "Yes, Delete It!",
						onClick: () => this._deleteTask(e.target.getAttribute("data-id")),
						className: "red"
					},
					{
						label: "No, nevermind",
						onClick: () => console.log("Good call")
					}
				]
			});
		}
	};

	_deleteTask = (tid) => {
		this._updateTask({
			tid: tid,
			delete: true
		});
	};

	_handleTaskTypeChange = (e) => {
		if (e && e.target && e.target.getAttribute("data-id")) {
			this._updateTask({
				tid: e.target.getAttribute("data-id"),
				type: e.target.value
			});
		}
	};

	_handleTaskStatusChange = (e) => {
		if (e && e.target && e.target.getAttribute("data-id")) {
			this._updateTask({
				tid: e.target.getAttribute("data-id"),
				status: e.target.value
			});
		}
	};

	_handleTaskForceShowChange = (e) => {
		if (e && e.target && e.target.getAttribute("data-id")) {
			this._updateTask({
				tid: e.target.getAttribute("data-id"),
				force_show: !(e.target.getAttribute("data-show") === "1")
			});
		}
	};

	_handleTaskContentChange = (name, value) => {
		if (name && value) {
			let orig_content;
			for (let i = 0; i < this.state.tasks.length; i++) {
				if (this.state.tasks[i]._id === name) {
					orig_content = this.state.tasks[i].content;
					break;
				}
			}
			// Only save if content changed
			if (orig_content !== value) {
				this._updateTask({
					tid: name,
					content: value
				});
			}
		}
	};

	getSectionData = (fields) => {
		let sectionFields = [],
			attemptArr = [],
			sectionGroups = [],
			ids = {},
			groupType,
			groupTitle;
		fields.forEach(function (field) {
			if (field.group_id) {
				let found = false;
				for (let i = 0; i < sectionGroups.length; i++) {
					if (sectionGroups[i].group_id === field.group_id) {
						found = true;
						sectionGroups[i].fields.push(field);
						break;
					}
				}
				if (!found) {
					const arr = field.group_id.split("_");
					sectionGroups.push({
						group_id: field.group_id,
						group_title: field.group_title,
						num: arr[arr.length - 1],
						fields: [field]
					});
				}
			} else if (!ids[field.field_type_id]) {
				ids[field.field_type_id] = 1;
				sectionFields.push(field);
			}
		});

		// Sort group divs
		if (sectionGroups.length > 0 && sectionGroups[0].group_id) {
			if (sectionGroups[0].group_id.includes("email")) {
				groupType = "Email";
				groupTitle = "Email Attempt";
			} else if (sectionGroups[0].group_id.includes("design")) {
				groupType = "Design";
				groupTitle = "Design Variation";
			}
			if (groupTitle) {
				sectionGroups.forEach(function (group) {
					let num = 0,
						attempt,
						version;
					for (let i = 0; i < group.fields.length; i++) {
						if (
							group.fields[i].field_type_id.includes("+attempt") ||
							group.fields[i].field_type_id.includes("+variation")
						) {
							attempt = group.fields[i].content;
							num += Number(attempt) * 100;
						} else if (group.fields[i].field_type_id.includes("+version")) {
							version = group.fields[i].content;
							if (version === "a") {
								num += 1;
							} else if (version === "b") {
								num += 2;
							} else if (version === "c") {
								num += 3;
							} else if (version === "d") {
								num += 4;
							} else if (version === "e") {
								num += 5;
							} else {
								num += 6;
							}
						}
						if (attempt && version) break;
					}
					group.display_title = groupType + " " + attempt + version;
					group.num = num;
				});
			}
		}

		// Build group divs
		if (sectionGroups.length > 0) {
			sectionGroups.sort((a, b) => a.num - b.num);
			sectionGroups.forEach(function (item) {
				item.fields.sort((a, b) => a.order - b.order);
				const idx = parseInt(item.num.toString()[0]) - 1;
				if (!attemptArr[idx]) {
					attemptArr[idx] = [item];
				} else {
					attemptArr[idx].push(item);
				}
			});
			attemptArr = attemptArr.filter(Boolean);
		}

		return { sectionFields, attemptArr, groupTitle };
	};

	getFieldData = (item) => {
		let dropboxPreviewSrc = "",
			figmaPreviewSrc = "",
			reader_content = this.getFieldValue(item),
			siteid_links = [],
			highlight_color;
		const hasLink = this.props.reader && linkify.find(reader_content).length > 0;
		if (
			this._checkUrl(reader_content) &&
			reader_content.indexOf("dropbox.com/s/") !== -1 &&
			(reader_content.indexOf(".png") !== -1 || reader_content.indexOf(".jpg") !== -1)
		) {
			// Dropbox preview link detected, extract link
			const links = linkify.find(reader_content);
			if (links && links.length > 0 && links[0].value) {
				dropboxPreviewSrc = links[0].value.split("?")[0] + "?dl=0&raw=1";
			}
		} else if (this._checkUrl(reader_content) && reader_content.indexOf("https://www.figma.com/file/") !== -1) {
			// figma preview link detected, extract link
			const links = linkify.find(reader_content);
			if (links && links.length > 0 && links[0].value) {
				figmaPreviewSrc = links[0].value;
			}
		}

		// Process links for reader mode
		if (hasLink) {
			// Replace dangerous chars
			if (reader_content.indexOf("<") !== -1) reader_content = reader_content.replaceAll("<", "&lt;");
			if (reader_content.indexOf(">") !== -1) reader_content = reader_content.replaceAll(">", "&gt;");
		}

		// Extract siteids and create external links
		if (item.field_type_id === "site_ids" && item.content && item.content.length > 0) {
			const words = item.content.match(/(\d{5})/g);
			if (words && words.length > 0) {
				words.forEach(function (siteid) {
					if (siteid.length === 5 && siteid_links.indexOf(siteid) === -1) {
						siteid_links.push([
							siteid,
							"https://www2.upsellit.com/admin/control/edit/site.jsp?siteID=" + siteid
						]);
					}
				});
			}
		}
		// Highlight changes in version 2+
		if (this.props.form.formvs) {
			let current_version = this.props.form.formvs.find((v) => v._id === this.props.form.vid);
			// Allow 2s delay for field creation
			let was_updated =
				new Date(item.updatedAt).getTime() - 2000 > new Date(current_version.versioned_at).getTime();
			if (current_version.versioned_at && current_version && current_version.version_main !== 1 && was_updated) {
				highlight_color = "rgb(255 255 0 / 20%)";
			}
		}
		return { dropboxPreviewSrc, reader_content, siteid_links, highlight_color, figmaPreviewSrc };
	};

	getEstimate(fields) {
		let time_estimate = 0;
		for (var i in fields) {
			fields[i].fields
				.map((curr) =>
					field_data[curr.field_type_id] && field_data[curr.field_type_id].estimate_time
						? field_data[curr.field_type_id].estimate_time(curr.content)
						: 0
				)
				.forEach((a) => (isNaN(Number(a)) ? null : (time_estimate += Number(a))));
		}

		return time_estimate;
	}

	getFieldValue = (item, type) => {
		type = type ? "_" + type : "";
		let id = item.field_id ? item.field_id : item;
		return this.state[id + type];
	};

	renderFields(fields, form) {
		return fields.map((item) => {
			return (
				<Field
					field_data={field_data}
					key={item._id}
					item={item}
					form={form}
					reader={this.props.reader}
					readOnly={this.state.command_key}
					show={this.props.show}
					_dateChanged={this._dateChanged.bind(this)}
					_datetimeChanged={this._datetimeChanged.bind(this)}
					_generateTimeStyles={this._generateTimeStyles.bind(this)}
					_handleFieldBlur={this._handleFieldBlur.bind(this)}
					_handleFieldKeyUp={this._handleFieldKeyUp.bind(this)}
					_handleForceShowHideClicked={this._handleForceShowHideClicked.bind(this)}
					_handleRevisionHistoryClicked={this._handleRevisionHistoryClicked.bind(this)}
					_optionSelected={this._optionSelected.bind(this)}
					_optionSelectedStatic={this._optionSelectedStatic.bind(this)}
					_optionSelectedStaticHour={this._optionSelectedStaticHour.bind(this)}
					_optionSelectedStaticMinute={this._optionSelectedStaticMinute.bind(this)}
					_toggleChanged={this._toggleChanged.bind(this)}
					getFieldValue={this.getFieldValue.bind(this)}
					getFieldData={this.getFieldData.bind(this)}
					reportDraftChange={this.reportDraftChange.bind(this)}
					settings={this.props.settings}
				></Field>
			);
		});
	}

	render() {
		return (
			<FormWrapperEl>
				<GlobalStyle />
				<Prompt
					key="block-nav"
					when={this.checkUnsavedFields(this.state).length > 0}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				{!this.state.showDisplay && (
					<div className="lds-ellipsis">
						<div />
						<div />
						<div />
						<div />
					</div>
				)}
				<div className="header-wrapper form">
					<TopNavWrapper
						page={this.props.page}
						form={this.props.form}
						user={this.props.user}
						handleDeleteFormvClick={this.props.handleDeleteFormvClick}
						handleAddFormvClick={this.props.handleAddFormvClick}
						redirectToFormv={this.props.redirectToFormv}
						length={this.checkUnsavedFields(this.state).length}
						_handleSaveAllClick={this._handleSaveAllClick}
						_handleCloneFormClicked={this._handleCloneFormClicked}
						_handleEditFormClicked={this._handleEditFormClicked}
						_handleDeleteFormClicked={this._handleDeleteFormClicked}
					/>
				</div>
				{/* <FormHeader
					page={this.props.page}
					form={this.props.form}
					user={this.props.user}
					length={this.checkUnsavedFields(this.state).length}
					handleDeleteFormvClick={this.props.handleDeleteFormvClick}
					handleAddFormvClick={this.props.handleAddFormvClick}
					redirectToFormv={this.props.redirectToFormv}
					_handleSaveAllClick={this._handleSaveAllClick}
					_handleCloneFormClicked={this._handleCloneFormClicked}
					_handleEditFormClicked={this._handleEditFormClicked}
					_handleDeleteFormClicked={this._handleDeleteFormClicked}
				/> */}
				<div className="body-wrapper full-width-for-print">
					<div
						className={
							"body-content-wrapper " +
							(this.props.form.form && this.props.form.form.type ? this.props.form.form.type : "")
						}
					>
						{this.props.form && this.props.form.fields && this.state.showDisplay && (
							<div className={"fields-body-wrapper" + (this.state.showDisplay ? " show" : "")}>
								{!this.props.reader && (
									<CompanyInfo form={this.props.form.form} user={this.props.user} />
								)}
								{!(this.props.reader && this.state.tasks.length === 0) && (
									<Tasks
										reader={this.props.reader}
										tasks={this.state.tasks}
										tasksPending={this.state.tasksPending}
										tasksPendingCount={this.state.tasksPendingCount}
										tasksCompletedCount={this.state.tasksCompletedCount}
										_createTask={this._createTask}
										_handleTaskTypeChange={this._handleTaskTypeChange}
										_handleTaskStatusChange={this._handleTaskStatusChange}
										_handleTaskContentChange={this._handleTaskContentChange}
										_generateTimeStyles={this._generateTimeStyles}
										renderTaskBreadcrumbs={this.renderTaskBreadcrumbs}
										_handleTaskDelete={this._handleTaskDelete}
									/>
								)}
								{!(this.state.images && this.state.images.length === 0 && this.props.reader) && (
									<Images
										reader={this.props.reader}
										processing={this.state.processing}
										processingMsg={this.state.processingMsg}
										_onDrop={this._onDrop}
										images={this.state.images}
										length={this.state.images.length}
										handleImageZoom={this.props.handleImageZoom}
										vid={this.props.form.vid}
									/>
								)}
								{sectionOrder.map((sectionName) => {
									if (
										!this.props.form ||
										!this.props.form.fields ||
										!this.props.form.fields[sectionName] ||
										!this.props.form.fields[sectionName].fields ||
										!this.props.form.fields[sectionName].fields.length > 0
									) {
										return "";
									}
									let { sectionFields, attemptArr, groupTitle } = this.getSectionData(
										this.props.form.fields[sectionName].fields
									);
									return (
										<Section
											key={sectionName + "_wrapper"}
											form={this.props.form}
											reader={this.props.reader}
											_handleAddGroupButtonClicked={this._handleAddGroupButtonClicked}
											renderFields={this.renderFields.bind(this)}
											_handleDeleteGroupButtonClicked={this._handleDeleteGroupButtonClicked}
											sectionFields={sectionFields}
											attemptArr={attemptArr}
											groupTitle={groupTitle}
											sectionName={sectionName}
										/>
									);
								})}
							</div>
						)}
						{this.props.form && this.props.form.fields && (
							<footer className="form-footer">
								<span className="">
									Dev Time Estimate: {(this.getEstimate(this.props.form.fields) / 8).toFixed(1)} days
								</span>
								<a
									href="https://docs.google.com/document/d/1-KHOJY4pIweU_5BsmaIBQdY6rqZoGVesigumpzS9iMM/edit?usp=sharing"
									target="_blank"
									className="badge"
									rel="noreferrer"
								>
									BETA
								</a>
								{this.props.form.form.type === "tt" && (
									<div
										style={{
											paddingTop: "2em",
											opacity: !this.props.form.form._company.adminId ? "0.4" : "1"
										}}
									>
										<button
											className={"btn"}
											disabled={!this.props.form.form._company.adminId}
											title={!this.props.form.form._company.adminId ? "Admin ID required" : ""}
											onClick={() =>
												this.postCreateCampaignData(
													this.props.form,
													"https://www2.upsellit.com/admin/control/edit/create_campaign.jsp"
												)
											}
										>
											Create Campaign
										</button>
										<a
											href="https://upsellit.myjetbrains.com/youtrack/issue/HD-1299/Create-Campaign-from-CSD-button"
											target="_blank"
											className="badge"
											rel="noreferrer"
										>
											BETA
										</a>
										{this.props.form.form._company.adminId && (
											<p style={{ fontSize: "10px", marginTop: "1em" }}>
												Currently, only TT eligible for 'Create Campaign'
											</p>
										)}
										{!this.props.form.form._company.adminId && (
											<p style={{ fontSize: "10px", marginTop: "1em" }}>
												Set Admin ID on Company Page
											</p>
										)}
									</div>
								)}
								{window.location.href.indexOf("test=create_campaign") !== -1 && (
									<div
										style={{
											paddingTop: "2em",
											opacity: !this.props.form.form._company.adminId ? "0.4" : "1"
										}}
									>
										<button
											className={"btn"}
											disabled={!this.props.form.form._company.adminId}
											title={!this.props.form.form._company.adminId ? "Admin ID required" : ""}
											onClick={() =>
												this.postCreateCampaignData(
													this.props.form,
													"https://www2.upsellit.com/admin/control/edit/company_timeline.jsp"
												)
											}
										>
											Create Timeline Entry
										</button>
										<a
											href="https://upsellit.myjetbrains.com/youtrack/agiles/121-7/current?issue=HD-1140"
											target="_blank"
											className="badge"
											rel="noreferrer"
										>
											WIP
										</a>
									</div>
								)}
							</footer>
						)}
					</div>
				</div>
			</FormWrapperEl>
		);
	}
}

export default FormWrapper;
