import styled, {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
	#light {
		color: var(--txt-dg);
		#body p {
			color: var(--txt-dg);
			&.form-type {
				color: #fff;
			}
		}
		#light .menu-wrapper {
			background: #fff;
		}
		.search-field {
			background: #fff;
			color: var(--txt-dg);
		}
		.activity-row, .salesforce-wrapper {
			background: #fff !important;
		}
		.company-row {
			background: #fff !important;
			.favicon {
				border: 1px solid var(--bg-l1);
				background: var(--bg-l1);
			}
		}
	}
`;


export const CompanyWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;

	h1 {
		color: var(--txt-dg);
		font-size: 1.5em;
		padding-bottom: 1em;
	}

	& .body-wrapper {
		padding: 4em 19em 4em 19em !important;
		text-align: center;
		&.show {
			animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
			-webkit-animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
			-moz-animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
			-o-animation: company-body-show-ani 0.25s ease-in-out 1 forwards;
		}
	}

	@keyframes company-body-show-ani {
		0% {
			opacity: 0;
			top: 70px;
		}
		100% {
			opacity: 1;
			top: 60px;
		}
	}
	
	.search-field {
		background: var(--bg-d0-dash);
		border-radius: 3px;
		&::-webkit-calendar-picker-indicator {
			opacity: 100;
			padding: 1em;
		}
	}
	.sort-wrapper,
	.filter-wrapper {
		color: var(--txt-lg);
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: 2em;
		text-align: right;
		select {
			font-size: 12px;
			color: var(--txt-lg);
			text-transform: uppercase;
			letter-spacing: 1px;
			background: none;
			border: none;
		}
		input {
			font-size: 12px;
			color: var(--txt-lg);
			text-transform: uppercase;
			letter-spacing: 1px;
			padding-right: 1em;
			background: none;
			border: none;
			padding: 1em;
		}
		button {
			font-size: 12px;
			color: var(--txt-lg);
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-left: 1em;
			background: none;
			border: none;
		}
	}
	#add-tag {
		font-size: 2em;
	}
	.tag-list {
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0.5em 0;
		min-height: 3em;
		div {
			display: inline-flex;
			background: var(--txt-dg);
			color: #fff;
			padding: 0 0.5em;
			border-radius: 3px;
			margin-left: 0.5em;
		}
	}

	& .company-column {
		display: inline-block;
		vertical-align: top;
		min-height: 100px;
		position: relative;
		width: 50%;
		padding: 2em;
		max-width: 400px;
	}

	& .body-wrapper.two-col .company-column {
		width: 48%;
		max-width: 500px;
	}

	& .company-column.search-wrapper input::placeholder {
		color: var(--txt-lg);
	}

	& .company-column h1 {
		color: var(--txt-lg);
		font-size: 13px;
		margin-bottom: 23px;
		vertical-align: top;
		span {
			font-size: 13px;
		}
		img {
			height: 18px;
			width: 18px;
			margin: -1px 8px 0 0;
			vertical-align: top;
			&.search {
				background-image: var(--icon-search-lg);
			}
			&.history {
				background-image: var(--icon-history-lg);
			}
			&.activity {
				background-image: var(--icon-activity-lg);
			}
			&.legacy {
				background-image: var(--icon-legacy-lg);
			}
		}
	}

	& .company-column .section-prompt {
		color: var(--txt-lg);
		text-align: center;
		font-size: 12px;
		margin-top: 10px;
		padding: 0 40px;
	}

	& .company-row {
		display: block;
		margin-top: 10px;
		background: var(--bg-d2);
		border-radius: 3px;
		border: 1px solid transparent;
		transition: background 0.05s, border 0.05s, top 0.5s, -moz-transform ease-in-out 0.05s;
		position: relative;
		min-height: 60px;

		&:hover {
			cursor: pointer;
			border: 1px solid var(--blue);
		}

		.click-zone {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 1;
			a {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				color: transparent;
			}
		}

		.favicon-wrapper {
			margin: 10px 0 0 15px;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			.form-type {
				text-transform: uppercase;
				font-size: 10px;
				padding: 5px;
				border-radius: 3px;
				font-weight: 900;
				width: 30px;
				margin-top: 3px;
				&.tt {
					background: var(--darkgreen);
				}
				&.lc {
					background: var(--violet);
				}
				&.pc {
					background: var(--blue);
				}
				&.em {
					background: var(--blue);
				}
				&.nc {
					background: var(--gray);
				}
				&.ce {
					background: var(--red);
				}
			}
		}

		.favicon {
			font-size: 12px;
			padding: 3px;
			border-radius: 3px;
			width: 22px;
			height: 22px;
			border: 1px solid var(--bg-d0);
			background: var(--bg-d0);
		}

		.status-wrapper {
			padding: 0;
			margin: 1px 0 0 0;
			height: 12px;
		}

		.status {
			width: 4px;
			height: 4px;
			border-radius: 2px;
			background: white;
			margin: 3px auto 0 auto;
			display: inline-block;
			vertical-align: top;
			&.active {
				background: var(--green);
			}

			&.paused {
				background: var(--red);
			}
		}

		.form-count {
			display: inline-block;
			vertical-align: top;
			color: var(--txt-lg);
			font-size: 10px;
			margin-left: 3px;
			margin-top: -2px;
			padding: 0;
		}

		.menu-hover-wrapper {
			height: 20px;
			width: 20px;
			position: absolute;
			top: 10px;
			right: 4px;
			border-radius: 3px;
			z-index: 2;
			text-align: right;
			.menu-icon {
				position: absolute;
				top: 0;
				right: 0;
				height: 20px;
				width: 20px;
				z-index: 10;
				color: white;
				font-size: 20px;
				text-align: center;
			}
			&:hover {
				cursor: pointer;
				background: var(--bg-l1);
				padding: 4px 0;
				width: auto;
				height: auto !important;
				z-index: 100 !important;
			}
			&:hover > .menu-icon {
				display: none;
			}
			.menu-wrapper {
				display: none;
				height: auto;
			}
			.menu-btn {
				font-size: 12px;
				color: var(--txt-d);
				text-align: left;
				margin: 0 4px;
				border-radius: 2px;
				padding: 2px 60px 0 10px;
				&:hover {
					color: white;
				}
				&.edit:hover {
					background: var(--blue);
				}
				&.clone:hover {
					background: var(--lt-green-bg);
				}
				&.delete:hover {
					background: var(--red);
				}
			}
			&:hover > .menu-wrapper {
				display: block;
			}
		}

		.delete-btn:hover > span {
			opacity: 1;
		}

		.text {
			padding: 10px 22px 10px 5px;
			height: auto !important;
			display: inline-block;
			vertical-align: top;
			width: calc(100% - 60px);
			text-align: left;
			margin-left: 5px;
		}

		.title {
			font-size: 13px;

			span {
				color: var(--bg-l1);
				border: 1px solid;
				font-size: 10px;
				padding: 2px 4px;
				position: relative;
				top: -1px;
				border-radius: 3px;
				margin-left: 5px;
			}

			&.siteid {
				margin-top: 1px;
				font-size: 11px;
				color: var(--txt-lg);
				span {
					color: white;
					display: inline-block;
					margin-top: 3px;
					margin-left: 0;
				}
				&.search span {
					background: rgba(244, 128, 36, 0.35);
					padding: 0 3px;
				}
			}

			.salesforce-icon {
				display: inline-block;
				vertical-align: top;
				height: 4px;
				width: 4px;
				border-radius: 2px;
				margin-left: 6px;
				margin-top: 8px;
				background: var(--salesforce-blue);
				padding: 0;
				border: none;
			}

			.legacy {
				font-size: 13px;
				color: var(--teal);
				padding: 3px 0 1px 0;
			}
		}

		.user {
			color: var(--txt-lg);
			font-size: 11px;
			margin-top: 1px;
			font-weight: 500;
			span.green {
				color: var(--green);
			}
			span.yellow {
				color: var(--darkyellow);
			}
			span.orange {
				color: var(--orange);
			}
		}
	}

	& .search-field {
		background: var(--bg-d2);
		border: 1px solid transparent;
		outline: none;
		width: 100%;
		font-size: 13px;
		border-radius: 3px;
		text-align: center;
		color: white;
		&:focus {
			border: 1px solid var(--blue);
		}
	}

	& .company-column {
		.legacy-wrapper {
			margin: 25px 0 0 0;
		}
		.legacy-wrapper .title {
			color: var(--teal);
		}
	}

	@media screen and (max-width: 1200px) {
		& .body-wrapper {
			padding: 4em 2em 6em 5em !important;
		}

		& .company-column {
			width: 100%;
			max-width: unset;
			display: block;
			margin: 25px auto 0 auto;
		}

		& .body-wrapper.two-col .company-column {
			width: 100%;
			max-width: unset;
		}
	}
`;
