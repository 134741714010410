// Resources
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // https://github.com/GA-MO/react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import TopNavWrapper from "./TopNavWrapper";
import { SalesforceInformation } from "../function/SalesforceInformation";
import { CompanySearch } from "../function/CompanySearch";

// Styles
import { CompanyWrapperEl, GlobalStyle } from "./styles/CompanyWrapper";

class CompanyWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Titles
			addTitle: "",
			// Forms
			formsSearch: [],
			formsHistory: [],
			formsLatest: [],
			formsCount: -1,
			formsLegacy: [],
			formsLegacyCount: -1,
			// Search
			sort: window.localStorage["csd_company_sort"] || "createdAt",
			search: "",
			searchSiteID: "",
			typing: false,
			displaySearchLoader: false,
			typingTimeout: 0,
			// Misc
			showDisplay: true,
			slideUp: false,
			records: []
		};
		this._changeSearch = this._changeSearch.bind(this);
	}

	fetchMore = () => {
		if (this.state.formsCount !== -1 && this.props.company && this.props.company._id) {
			// Fetch more forms
			this.Auth.getMoreForms({
				id: this.props.company._id,
				loaded: this.state.formsLatest.length
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.forms) {
						this.setState({
							formsLatest: this.state.formsLatest.concat(res.forms)
						});
					} else {
						console.error("[ fetchMore ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ fetchMore ] POST error:", err);
				});
		}
	};

	fetchMoreLegacy = () => {
		if (this.props.company.salesforceId && this.state.formsLegacy.length) {
			this.Auth.getMoreFormsLegacy({
				id: this.props.company.salesforceId,
				loaded: this.state.formsLegacy.length
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.legacies) {
						this.setState({
							formsLegacy: this.state.formsLegacy.concat(res.legacies)
						});
					} else {
						console.error("[ fetchMoreLegacy ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ fetchMoreLegacy ] POST error:", err);
				});
		}
	};

	initFetchCSD = () => {
		if (this.props.company && this.props.company._id) {
			this.Auth.getFormsInit({
				id: this.props.company._id,
				sf_id: this.props.company.salesforceId
			})
				.then((res) => {
					if (res.error) {
						console.error(res.error);
					} else if (res.forms && res.histories) {
						this.setState({
							formsLatest: res.forms,
							formsCount: res.forms_count,
							formsHistory: res.histories,
							formsLegacy: res.legacies || [],
							formsLegacyCount: res.legacies_count || -1,
							showDisplay: true
						});
					} else {
						console.error("[ initFetchCSD ] ERROR - Missing res data:", res);
					}
				})
				.catch((err) => {
					console.log("[ initFetchCSD ] POST error:", err);
				});
		} else {
			setTimeout(() => {
				this.initFetchCSD();
			}, 1000);
		}
	};

	searchFetch = () => {
		if (this.props.page === "company" && this.props.company && this.props.company._id) {
			this.Auth.getFormsSearch({
				id: this.props.company._id,
				search: this.state.search,
				sf_id: this.props.company.salesforceId
			})
				.then((res) => {
					if (
						typeof res.forms !== "undefined" &&
						typeof res.fields !== "undefined" &&
						typeof res.legacies !== "undefined"
					) {
						this.setState({
							searchSiteID: this.state.search,
							displaySearchLoader: false,
							formsSearch: res.forms.concat(res.fields).concat(res.legacies)
						});
					}
				})
				.catch((err) => {
					console.log("[ searchFetch ] POST error:", err);
				});
		}
	};

	_changeSearch = (e) => {
		if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
		this.setState({
			displaySearchLoader: e.target.value.length > 0,
			search: e.target.value,
			typing: false,
			typingTimeout: setTimeout(() => {
				if (e.target.value.length > 0) {
					this.searchFetch();
				} else {
					this.setState({
						searchTitle: "",
						displaySearchLoader: false,
						formsSearch: []
					});
				}
			}, 1000)
		});
	};

	_handleEditFormClicked = (e) => {
		const parent = e.target.parentNode;
		const id = parent.getAttribute("data-id");
		const title = parent.getAttribute("data-title");
		if (id && title) {
			this.props.handleAddCSD(id, title);
		}
	};

	_handleCloneFormClicked = (e) => {
		const parent = e.target.parentNode;
		const id = parent.getAttribute("data-id");
		const type = parent.getAttribute("data-formtype");
		const title = parent.getAttribute("data-title");
		if (id && type) {
			confirmAlert({
				title: "Are you sure you want to clone this CSD?.",
				message:
					"Only clone if you are creating a very similar CSD for a NEW Site ID (not the one you are cloning from). If this is for the same Site ID, please create a new major version within the existing CSD page.",
				overlayClassName: "confirm-alert-bg-wrapper clone",
				buttons: [
					{
						label: "Yes, clone it!",
						onClick: () => this.props.handleCloneCSD(id, type, title),
						className: "green"
					},
					{
						label: "No, nevermind",
						onClick: () => console.log("Good call")
					}
				]
			});
		}
	};

	_handleDeleteFormClicked = (e) => {
		const id = e.target.parentNode.getAttribute("data-id");
		if (id) {
			confirmAlert({
				title: "Are you sure you want to delete this CSD? This action cannot be undone.",
				message:
					"\"If that's true, if you don't know who I am, then maybe your best course would be to tread lightly.\"",
				overlayClassName: "confirm-alert-bg-wrapper delete",
				buttons: [
					{
						label: "Yes, delete it!",
						onClick: () => this.props.handleDeleteFormClick(id),
						className: "red"
					},
					{
						label: "No, nevermind",
						onClick: () => console.log("handleDeleteFormClick cancelled")
					}
				]
			});
		}
	};

	_handleAddClicked = () => {
		if (this.props.page === "company" && typeof this.props.handleAddCSD === "function") {
			this.props.handleAddCSD();
		}
	};

	handleSort = (event) => {
		window.localStorage.setItem("csd_company_sort", event.target.value);
		this.setState({
			sort: event.target.value
		});
	};

	setSalesforceRecords = (records) => {
		this.setState({
			records: records
		});
	};

	componentDidMount() {
		if (this.props.page === "company") {
			this.setState(
				{
					showDisplay: false,
					slideUp: true,
					addTitle: "New CSD"
				},
				this.initFetchCSD
			);
		}
	}

	render() {
		return (
			<React.Fragment>
				<GlobalStyle />
				<CompanyWrapperEl>
					{!this.state.showDisplay && (
						<div className="lds-ellipsis">
							<div />
							<div />
							<div />
							<div />
						</div>
					)}
					<div className={"header-wrapper company"}>
						<TopNavWrapper page={this.props.page} user={this.props.user} />
					</div>
					{this.state.showDisplay && (
						<div className={"body-wrapper" + (this.state.showDisplay && this.state.slideUp ? " show" : "")}>
							<div className="">
								<a
									className="btn help-btn"
									target="_blank"
									href="/wiki/salesforce#goto_opportunity__csd_best_practices"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20px"
										height="20px"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M10 16.584V18.9996C10 20.1042 10.8954 20.9996 12 20.9996C13.1046 20.9996 14 20.1042 14 18.9996L14 16.584M12 3V4M18.3643 5.63574L17.6572 6.34285M5.63574 5.63574L6.34285 6.34285M4 12H3M21 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</a>
								<button className="btn mb add-btn noselect" onClick={this._handleAddClicked}>
									{this.state.addTitle}
								</button>
							</div>
							<CompanySearch
								_handleCloneFormClicked={this._handleCloneFormClicked}
								_handleEditFormClicked={this._handleEditFormClicked}
								_handleDeleteFormClicked={this._handleDeleteFormClicked}
								_changeSearch={this._changeSearch}
								handleSort={this.handleSort}
								getType={this.props.getType}
								generateTimeStyles={this.generateTimeStyles}
								displaySearchLoader={this.state.displaySearchLoader}
								formsHistory={this.state.formsHistory}
								searchSiteID={this.state.searchSiteID}
								sort={this.state.sort}
								user={this.props.user}
								arr={
									this.state.search !== "" || this.state.formsSearch.length > 0
										? this.state.formsSearch
										: this.state.formsLatest
								}
							></CompanySearch>

							{this.props.company.salesforceId && (
								<SalesforceInformation
									length={this.state.records.length}
									setSalesforceRecords={this.setSalesforceRecords}
									user={this.props.user}
									company={this.props.company}
								></SalesforceInformation>
							)}
						</div>
					)}
				</CompanyWrapperEl>
			</React.Fragment>
		);
	}
}

export default CompanyWrapper;
